.List {
  .Photos {
    display: grid;
    grid-template-columns: repeat(3, 33.33%);

    .Photo {
      padding: 5px;
      display: flex;
      flex-direction: column;
      > div {
        img {
          cursor: pointer;
        }
      }
      span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

:global {
  .ant-image-preview-switch-left,
  .ant-image-preview-switch-right {
    width: calc(44px * 2.5);
    height: calc(44px * 2.5);
  }
  .ant-image-preview-switch-left > .anticon,
  .ant-image-preview-switch-right > .anticon {
    font-size: 80px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;