@import 'shared/assets/styles/main.module.less';

.AttemptsTwoFa {
}

.Yellow {
  color: @yellow;
}

.Red {
  color: @danger;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;