@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@700&display=swap);
@font-face {
  font-family: 'FasterOne-Regular';
  src: url(/static/media/FasterOne-Regular.0fc4600c.ttf) format('truetype');
}

:global .ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
:global .ant-btn-dangerous:hover,
:global .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
:global .ant-card,
:global .ant-spin-nested-loading,
:global .ant-page-header,
:global .ant-btn,
:global .ant-select-selector,
:global .ant-picker,
:global .ant-input-affix-wrapper,
:global .ant-input,
:global .ant-modal-content {
  border-radius: 4px;
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}
textarea:focus,
input:focus {
  outline: none;
}
.icon-button {
  color: white;
}
.icon-button:focus {
  color: white;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.ShowDocuments_ShowDocuments__1llpQ {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr {
  padding: 0.5rem;
  position: relative;
  /* for touch screen devices */
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(177, 177, 177, 0.4);
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj > button {
  padding: 0;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj > span button {
  padding: 0;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj > button + button {
  margin-left: 10px;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj .ShowDocuments_Primary__3TTuM > span,
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj .ShowDocuments_Danger__2Zi8j > span {
  color: #ffffff;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj .ShowDocuments_Primary__3TTuM > span:hover {
  color: #1c58e8;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj .ShowDocuments_Danger__2Zi8j > span:hover {
  color: #ff8a4d;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj .ShowDocuments_Icon__bBv-n {
  font-size: 24px;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Image__2-sv1 {
  transition: all 0.3s ease 0s;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  overflow: hidden;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Image__2-sv1 img {
  transition: all 0.3s ease 0s;
  filter: blur(0px);
  transform: scale(1.1);
  object-fit: cover;
  padding: 7px;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr:hover .ShowDocuments_Title__1I0aj {
  opacity: 1;
}
.ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr:hover .ShowDocuments_Image__2-sv1 {
  filter: blur(2px);
}
@media (hover: none) {
  .ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Title__1I0aj {
    opacity: 1;
  }
  .ShowDocuments_ShowDocuments__1llpQ .ShowDocuments_Item__1Usqr .ShowDocuments_Image__2-sv1 {
    filter: blur(2px);
  }
}

.Footer_Footer__3I2rV {
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.Footer_Footer__3I2rV .Footer_Bytton__4SXOY {
  padding: 0;
}

.FeedBack_FeedBack__LV-Ih .FeedBack_RadioGroup__2J1FF {
  display: flex;
  flex-direction: column;
}
.FeedBack_FeedBack__LV-Ih .FeedBack_TextArea__dkubG {
  margin-top: 20px;
}

.Reporting_Reporting__2pLJv .Reporting_RangePicker__1E-mu,
.Reporting_Reporting__2pLJv .Reporting_Select__hBVqV {
  width: 100%;
}
.Reporting_Reporting__2pLJv > div + div {
  margin-top: 10px;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Menu_DangerColor__hj55H {
  color: #ff8a4d;
}
.Menu_WarningColor__3jR_n {
  color: #ff8a4d;
}
.Menu_PrimaryColor__1myrc {
  color: #1c58e8;
}
.Menu_SuccessColor__3YT0d {
  color: #46d485;
}
.Menu_DangerButton__2pnkE {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Menu_DangerButton__2pnkE:hover,
.Menu_DangerButton__2pnkE:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Menu_DangerButton__2pnkE:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Menu_WarningButton__2DycV {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Menu_WarningButton__2DycV:hover,
.Menu_WarningButton__2DycV:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Menu_WarningButton__2DycV:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Menu_PrimaryButton__3k3Eh {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Menu_PrimaryButton__3k3Eh:hover,
.Menu_PrimaryButton__3k3Eh:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Menu_PrimaryButton__3k3Eh:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Menu_SuccessButton__3PyS1 {
  background: #46d485;
  border-color: #46d485;
}
.Menu_SuccessButton__3PyS1:hover,
.Menu_SuccessButton__3PyS1:focus {
  background: #46d485;
  border-color: #46d485;
}
.Menu_SuccessButton__3PyS1:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Menu_Menu__Ew9ZH {
  min-width: 200px;
  margin-right: 15px;
}
.Menu_Menu__Ew9ZH .Menu_Disabled__23M_f {
  pointer-events: none;
  opacity: 0.4;
}
.Menu_Menu__Ew9ZH .Menu_Item__2m0DS {
  display: flex;
  align-items: center;
  margin: 2px;
  padding: 5px 10px;
  cursor: pointer;
}
.Menu_Menu__Ew9ZH .Menu_Item__2m0DS span + span {
  margin-left: 10px;
}
.Menu_Menu__Ew9ZH .Menu_Item__2m0DS span svg {
  font-size: 18px;
  color: #1c58e8;
}
.Menu_Menu__Ew9ZH .Menu_Item__2m0DS:hover {
  background-color: rgba(lighter(#1c58e8, 5%), 0.5);
  border-radius: 5px;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Header_DangerColor__3z8Ku {
  color: #ff8a4d;
}
.Header_WarningColor__3HlSk {
  color: #ff8a4d;
}
.Header_PrimaryColor__1f6c6 {
  color: #1c58e8;
}
.Header_SuccessColor__2T9Si {
  color: #46d485;
}
.Header_DangerButton__1fc8Z {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_DangerButton__1fc8Z:hover,
.Header_DangerButton__1fc8Z:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_DangerButton__1fc8Z:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Header_WarningButton__3e-wb {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_WarningButton__3e-wb:hover,
.Header_WarningButton__3e-wb:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_WarningButton__3e-wb:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Header_PrimaryButton__3IzwF {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Header_PrimaryButton__3IzwF:hover,
.Header_PrimaryButton__3IzwF:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Header_PrimaryButton__3IzwF:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Header_SuccessButton__2Yxda {
  background: #46d485;
  border-color: #46d485;
}
.Header_SuccessButton__2Yxda:hover,
.Header_SuccessButton__2Yxda:focus {
  background: #46d485;
  border-color: #46d485;
}
.Header_SuccessButton__2Yxda:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
@mixin one-third {
  width: calc(70% / 3);
  @media (max-width: 1024px) {
    width: calc(100% / 3);
  }
}
.Header_Header__16z7k {
  height: 60px;
  background-color: white;
}
.Header_Header__16z7k .Header_Content__2sI_h {
  max-width: calc(100% - 40px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.Header_Header__16z7k .Header_Content__2sI_h .Header_Menu__1_c7i {
  display: unset;
  @include one-third;
}
.Header_Header__16z7k .Header_Content__2sI_h .Header_Logo__H8buj {
  cursor: pointer;
  display: flex;
  justify-content: center;
  @include one-third;
}
.Header_Header__16z7k .Header_Content__2sI_h .Header_Logo__H8buj .Header_LogoPartner__3vY3N {
  max-width: 97px;
}
@media all and (max-width: 360px) {
  .Header_Header__16z7k .Header_Content__2sI_h .Header_Logo__H8buj .Header_LogoPartner__3vY3N {
    max-width: 70px;
  }
}
.Header_Header__16z7k .Header_Content__2sI_h .Header_Button__1DGrv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  @include one-third;
}
.Header_Header__16z7k .Header_Content__2sI_h .Header_Button__1DGrv .Header_Title__3Han2 {
  color: #a9a4a4;
  font-size: 16px;
  mix-blend-mode: normal;
  opacity: 0.85;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  overflow: hidden;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .Header_Header__16z7k .Header_Content__2sI_h .Header_Button__1DGrv .Header_Title__3Han2 {
    display: none;
  }
}
.Header_Header__16z7k .Header_Content__2sI_h .Header_Button__1DGrv > span {
  display: flex;
  align-items: center;
}
@media all and (max-width: 1200px) {
  .Header_Header__16z7k .Header_Content__2sI_h {
    max-width: 100%;
  }
}
.Header_Email__YUyBi {
  padding: 8px 16px;
  color: #a9a4a4;
  font-size: 14px;
  line-height: 1.5715;
  cursor: unset;
  display: flex;
  justify-content: center;
}
.Header_Email__YUyBi:hover {
  background-color: #fff !important;
}
.Header_Item__Pk5gb {
  display: flex;
  align-items: center;
  margin: 2px;
  padding: 5px 10px;
  cursor: pointer;
}
.Header_Item__Pk5gb span + span {
  margin-left: 0;
}
.Header_Item__Pk5gb span svg {
  font-size: 18px;
  color: #1c58e8;
}
.Header_Item__Pk5gb:hover {
  background-color: rgba(lighter(#1c58e8, 5%), 0.5);
  border-radius: 5px;
}
.Header_Devider__3eLpF {
  padding: 0;
  cursor: unset;
}
.Header_Devider__3eLpF .Header_Divider__2RaYm {
  margin: 5px 0;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.MainLayout_DangerColor__2Rcvo {
  color: #ff8a4d;
}
.MainLayout_WarningColor__2fVc0 {
  color: #ff8a4d;
}
.MainLayout_PrimaryColor__V-cwj {
  color: #1c58e8;
}
.MainLayout_SuccessColor__2XW8L {
  color: #46d485;
}
.MainLayout_DangerButton__1CjW6 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.MainLayout_DangerButton__1CjW6:hover,
.MainLayout_DangerButton__1CjW6:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.MainLayout_DangerButton__1CjW6:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.MainLayout_WarningButton__TQg61 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.MainLayout_WarningButton__TQg61:hover,
.MainLayout_WarningButton__TQg61:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.MainLayout_WarningButton__TQg61:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.MainLayout_PrimaryButton__1-r_w {
  background: #1c58e8;
  border-color: #1c58e8;
}
.MainLayout_PrimaryButton__1-r_w:hover,
.MainLayout_PrimaryButton__1-r_w:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.MainLayout_PrimaryButton__1-r_w:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.MainLayout_SuccessButton__2WYAm {
  background: #46d485;
  border-color: #46d485;
}
.MainLayout_SuccessButton__2WYAm:hover,
.MainLayout_SuccessButton__2WYAm:focus {
  background: #46d485;
  border-color: #46d485;
}
.MainLayout_SuccessButton__2WYAm:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.MainLayout_Container__2ruLt {
  margin-top: 20px !important;
  margin: 0 auto;
  max-width: calc(100% - 40px);
  display: block;
}
@media all and (max-width: 1200px) {
  .MainLayout_Container__2ruLt {
    margin: 10px 10px 0 10px !important;
    max-width: 100%;
  }
}
@media all and (max-width: 768px) {
  .MainLayout_Container__2ruLt {
    margin-top: 10px !important;
    margin: 10px 5px 0 5px !important;
    max-width: 100%;
  }
}
.MainLayout_Container__2ruLt .MainLayout_SideBar__3LZSf {
  display: none;
}
.MainLayout_Container__2ruLt .MainLayout_Content__uazez {
  flex: 1 1;
}

.Header_Header__1slUy .ant-page-header {
  padding-top: 0;
  background-color: #fff;
  margin-bottom: 10px;
}
.Header_Header__1slUy .ant-page-header .ant-page-header-heading {
  padding-top: 10px;
}
.Header_Header__1slUy .ant-page-header .ant-page-header-heading .ant-page-header-heading-title {
  padding: 0px;
  display: inline-block;
}
.Header_Header__1slUy .ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}
.Header_Status__1vMB8 {
  display: flex;
  align-items: center;
}

.DeliveryRequest_DeliveryRequest__3YRVj .DeliveryRequest_Body__1Bpom {
  display: flex;
  height: fit-content;
}
.DeliveryRequest_DeliveryRequest__3YRVj .DeliveryRequest_Body__1Bpom > * + * {
  margin-left: 15px;
}
.DeliveryRequest_DeliveryRequest__3YRVj .DeliveryRequest_Body__1Bpom > * {
  width: calc(50% - 15px / 2);
}
.DeliveryRequest_DeliveryRequest__3YRVj .DeliveryRequest_Body__1Bpom .DeliveryRequest_Card__3Sj8f {
  height: fit-content;
}
.DeliveryRequest_Hide__VwvB5 {
  display: none !important;
}

.SelectProduct_SelectProduct__2cpjH {
  width: 100%;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Form_DangerColor__2qrmO {
  color: #ff8a4d;
}
.Form_WarningColor__3DJTV {
  color: #ff8a4d;
}
.Form_PrimaryColor__1-YHA {
  color: #1c58e8;
}
.Form_SuccessColor__31AQ8 {
  color: #46d485;
}
.Form_DangerButton__2xwgJ {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Form_DangerButton__2xwgJ:hover,
.Form_DangerButton__2xwgJ:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Form_DangerButton__2xwgJ:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Form_WarningButton__1sqW2 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Form_WarningButton__1sqW2:hover,
.Form_WarningButton__1sqW2:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Form_WarningButton__1sqW2:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Form_PrimaryButton__2gPmF {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Form_PrimaryButton__2gPmF:hover,
.Form_PrimaryButton__2gPmF:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Form_PrimaryButton__2gPmF:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Form_SuccessButton__3fQYT {
  background: #46d485;
  border-color: #46d485;
}
.Form_SuccessButton__3fQYT:hover,
.Form_SuccessButton__3fQYT:focus {
  background: #46d485;
  border-color: #46d485;
}
.Form_SuccessButton__3fQYT:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Form_Form__1HQoh > * + * {
  margin-top: 10px;
}
.Form_Form__1HQoh .Form_Double__22cXR {
  display: flex;
}
.Form_Form__1HQoh .Form_Double__22cXR > * {
  flex: 1 1;
}
.Form_Form__1HQoh .Form_Double__22cXR > * + * {
  margin-left: 10px;
}
@media all and (max-width: 768px) {
  .Form_Form__1HQoh .Form_Double__22cXR {
    flex-direction: column;
  }
  .Form_Form__1HQoh .Form_Double__22cXR > * + * {
    margin-left: 0;
    margin-top: 10px;
  }
}

.SelectCity_SelectCity__2t3-F,
.SelectCity_SelectCity__2t3-F .SelectCity_Select__1-7cf {
  width: 100%;
}

.SelectDate_SelectDate__9Fo84,
.SelectDate_SelectDate__9Fo84 .SelectDate_Select__Wb3j7 {
  width: 100%;
}

.SelectAddress_SelectAddress__3wqbH,
.SelectAddress_SelectAddress__3wqbH .SelectAddress_Select__2BVmR {
  width: 100%;
}

.AutoCompleteAddress_AutoCompleteAddress__6UBKq {
  width: 100%;
  position: relative;
}
.AutoCompleteAddress_AutoCompleteAddress__6UBKq .AutoCompleteAddress_AutoComplete__25fCU {
  width: 100%;
}
.AutoCompleteAddress_AutoCompleteAddress__6UBKq .AutoCompleteAddress_Spiner__3UQpD {
  position: absolute;
  top: calc(50% - 7px);
  right: 5px;
}

.SelectTimeInterval_SwitchExactInterval__f1Rer {
  margin-bottom: 10px;
}
.SelectTimeInterval_Select__3A3iT {
  width: 100%;
}

.AutoCompleteFio_AutoCompleteFio__2WtV4 {
  width: 100%;
}
.AutoCompleteFio_AutoCompleteFio__2WtV4 .ant-input-search-button,
.AutoCompleteFio_AutoCompleteFio__2WtV4 .anticon + .anticon-search {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SelectFullName_SelectFullName__38wEp,
.SelectFullName_SelectFullName__38wEp .SelectFullName_Select__rabjK {
  width: 100%;
}

.SelectPhone_SelectPhone__2Djk3,
.SelectPhone_SelectPhone__2Djk3 .SelectPhone_Select__1AXH0 {
  width: 100%;
}
.SelectPhone_SelectPhone__2Djk3 .SelectPhone_Hint__B930Q {
  font-size: 12px;
}

.SelectEmail_SelectEmail__3WpT6,
.SelectEmail_SelectEmail__3WpT6 .SelectEmail_Select__z_JAp {
  width: 100%;
}
.SelectEmail_SelectEmail__3WpT6 .SelectEmail_Hint__12yAI {
  font-size: 12px;
}

.SelectCompanyName_SelectCompanyName__9Gz0a,
.SelectCompanyName_SelectCompanyName__9Gz0a .SelectCompanyName_Select__eZ40r {
  width: 100%;
}

.SelectNotes_SelectNotes__2B71k,
.SelectNotes_SelectNotes__2B71k .SelectNotes_Select__bgsPU {
  width: 100%;
}

.ControlPanel_ControlPanel__3O_eG {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.SelectInn_SelectInn__CwqF5,
.SelectInn_SelectInn__CwqF5 .SelectInn_Select__1iiC9 {
  width: 100%;
}

.SelectExternalIdRequired_SelectExternalIdRequired__3PeQS,
.SelectExternalIdRequired_SelectExternalIdRequired__3PeQS .SelectExternalIdRequired_Select__3v1ol {
  width: 100%;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Map_DangerColor___hHYt {
  color: #ff8a4d;
}
.Map_WarningColor__3Ik8n {
  color: #ff8a4d;
}
.Map_PrimaryColor__YeSiG {
  color: #1c58e8;
}
.Map_SuccessColor__2_eJU {
  color: #46d485;
}
.Map_DangerButton__3zuIW {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Map_DangerButton__3zuIW:hover,
.Map_DangerButton__3zuIW:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Map_DangerButton__3zuIW:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Map_WarningButton__1syF9 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Map_WarningButton__1syF9:hover,
.Map_WarningButton__1syF9:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Map_WarningButton__1syF9:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Map_PrimaryButton__uGAKI {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Map_PrimaryButton__uGAKI:hover,
.Map_PrimaryButton__uGAKI:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Map_PrimaryButton__uGAKI:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Map_SuccessButton__2pXmL {
  background: #46d485;
  border-color: #46d485;
}
.Map_SuccessButton__2pXmL:hover,
.Map_SuccessButton__2pXmL:focus {
  background: #46d485;
  border-color: #46d485;
}
.Map_SuccessButton__2pXmL:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Map_Map__68ayI [class*='ymaps-2'][class*='-ground-pane'] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
}

.Download_Download__2oDiz .Download_Count__2Drb_ {
  color: gainsboro;
  margin-left: 4px;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.CancelModal_DangerColor__2cnkH {
  color: #ff8a4d;
}
.CancelModal_WarningColor__t_RC4 {
  color: #ff8a4d;
}
.CancelModal_PrimaryColor__1uXlD {
  color: #1c58e8;
}
.CancelModal_SuccessColor__1Fcl- {
  color: #46d485;
}
.CancelModal_DangerButton__1XDY9 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CancelModal_DangerButton__1XDY9:hover,
.CancelModal_DangerButton__1XDY9:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CancelModal_DangerButton__1XDY9:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.CancelModal_WarningButton__1PJsk {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CancelModal_WarningButton__1PJsk:hover,
.CancelModal_WarningButton__1PJsk:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CancelModal_WarningButton__1PJsk:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.CancelModal_PrimaryButton__2hi3A {
  background: #1c58e8;
  border-color: #1c58e8;
}
.CancelModal_PrimaryButton__2hi3A:hover,
.CancelModal_PrimaryButton__2hi3A:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.CancelModal_PrimaryButton__2hi3A:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.CancelModal_SuccessButton__2CLqH {
  background: #46d485;
  border-color: #46d485;
}
.CancelModal_SuccessButton__2CLqH:hover,
.CancelModal_SuccessButton__2CLqH:focus {
  background: #46d485;
  border-color: #46d485;
}
.CancelModal_SuccessButton__2CLqH:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.CancelModal_CancelModal__2xzA3 .CancelModal_RadioGroup__GK6EN {
  display: flex;
  flex-direction: column;
}
.CancelModal_CancelModal__2xzA3 .CancelModal_Comment__lYQYz {
  margin-top: 1rem;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.OkModal_DangerColor__3CmYV {
  color: #ff8a4d;
}
.OkModal_WarningColor__3wy39 {
  color: #ff8a4d;
}
.OkModal_PrimaryColor__FCYpt {
  color: #1c58e8;
}
.OkModal_SuccessColor__QWLI6 {
  color: #46d485;
}
.OkModal_DangerButton__131cn {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.OkModal_DangerButton__131cn:hover,
.OkModal_DangerButton__131cn:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.OkModal_DangerButton__131cn:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.OkModal_WarningButton__1M7_O {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.OkModal_WarningButton__1M7_O:hover,
.OkModal_WarningButton__1M7_O:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.OkModal_WarningButton__1M7_O:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.OkModal_PrimaryButton__3oqSF {
  background: #1c58e8;
  border-color: #1c58e8;
}
.OkModal_PrimaryButton__3oqSF:hover,
.OkModal_PrimaryButton__3oqSF:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.OkModal_PrimaryButton__3oqSF:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.OkModal_SuccessButton__gkyY_ {
  background: #46d485;
  border-color: #46d485;
}
.OkModal_SuccessButton__gkyY_:hover,
.OkModal_SuccessButton__gkyY_:focus {
  background: #46d485;
  border-color: #46d485;
}
.OkModal_SuccessButton__gkyY_:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.RejectModal_DangerColor__1IfUH {
  color: #ff8a4d;
}
.RejectModal_WarningColor__374SC {
  color: #ff8a4d;
}
.RejectModal_PrimaryColor__Ji62s {
  color: #1c58e8;
}
.RejectModal_SuccessColor__2Zofc {
  color: #46d485;
}
.RejectModal_DangerButton__3rzzO {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.RejectModal_DangerButton__3rzzO:hover,
.RejectModal_DangerButton__3rzzO:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.RejectModal_DangerButton__3rzzO:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.RejectModal_WarningButton__bwfXs {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.RejectModal_WarningButton__bwfXs:hover,
.RejectModal_WarningButton__bwfXs:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.RejectModal_WarningButton__bwfXs:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.RejectModal_PrimaryButton__2kFPu {
  background: #1c58e8;
  border-color: #1c58e8;
}
.RejectModal_PrimaryButton__2kFPu:hover,
.RejectModal_PrimaryButton__2kFPu:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.RejectModal_PrimaryButton__2kFPu:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.RejectModal_SuccessButton__3to1l {
  background: #46d485;
  border-color: #46d485;
}
.RejectModal_SuccessButton__3to1l:hover,
.RejectModal_SuccessButton__3to1l:focus {
  background: #46d485;
  border-color: #46d485;
}
.RejectModal_SuccessButton__3to1l:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.RejectModal_RejectModal__2s8xQ .RejectModal_RadioGroup__RYqYK {
  display: flex;
  flex-direction: column;
}
.RejectModal_RejectModal__2s8xQ .RejectModal_Comment__YKKBn {
  margin-top: 1rem;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}


.SelectCity_SelectCity__uKPxa {
  width: 100%;
  min-width: 150px;
}

.SelectDeliveryRequestStatus_SelectDeliveryRequestStatus__2s1xN {
  width: 100%;
  min-width: 150px;
}

.SelectUsers_SelectUsers__lN4q_ {
  width: 100%;
  min-width: 150px;
}

.SelectProduct_SelectProduct__13fxW {
  width: 100%;
  min-width: 300px;
}

.MoreMenu_Button__160gA {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MoreMenu_Button__160gA > span {
  display: flex;
  align-items: center;
}
.MoreMenu_Item__9tJhg {
  display: flex;
  align-items: center;
}
.MoreMenu_Item__9tJhg > span {
  font-size: 16px !important;
}

.SelectDeliveryStatus_SelectDeliveryStatus__2tFil {
  width: 100%;
  min-width: 150px;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Table_DangerColor__XXK9K {
  color: #ff8a4d;
}
.Table_WarningColor__2_yYh {
  color: #ff8a4d;
}
.Table_PrimaryColor__2-OmJ {
  color: #1c58e8;
}
.Table_SuccessColor__3Utsk {
  color: #46d485;
}
.Table_DangerButton__1BjyK {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Table_DangerButton__1BjyK:hover,
.Table_DangerButton__1BjyK:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Table_DangerButton__1BjyK:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Table_WarningButton__1vLXy {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Table_WarningButton__1vLXy:hover,
.Table_WarningButton__1vLXy:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Table_WarningButton__1vLXy:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Table_PrimaryButton__2b96D {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Table_PrimaryButton__2b96D:hover,
.Table_PrimaryButton__2b96D:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Table_PrimaryButton__2b96D:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Table_SuccessButton__2pCk8 {
  background: #46d485;
  border-color: #46d485;
}
.Table_SuccessButton__2pCk8:hover,
.Table_SuccessButton__2pCk8:focus {
  background: #46d485;
  border-color: #46d485;
}
.Table_SuccessButton__2pCk8:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Table_Table__MAUpI {
  margin-top: 15px;
  overflow: hidden;
  width: auto;
}
.Table_Table__MAUpI .ant-table-row {
  cursor: pointer;
}
.Table_Table__MAUpI .ant-table-content {
  overflow: auto;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.ErrorLine_DangerColor__3csA_ {
  color: #ff8a4d;
}
.ErrorLine_WarningColor__2AHR6 {
  color: #ff8a4d;
}
.ErrorLine_PrimaryColor__21GN5 {
  color: #1c58e8;
}
.ErrorLine_SuccessColor__RetO2 {
  color: #46d485;
}
.ErrorLine_DangerButton__3Rqnj {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ErrorLine_DangerButton__3Rqnj:hover,
.ErrorLine_DangerButton__3Rqnj:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ErrorLine_DangerButton__3Rqnj:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ErrorLine_WarningButton__2TeV3 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ErrorLine_WarningButton__2TeV3:hover,
.ErrorLine_WarningButton__2TeV3:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ErrorLine_WarningButton__2TeV3:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ErrorLine_PrimaryButton__GNBmq {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ErrorLine_PrimaryButton__GNBmq:hover,
.ErrorLine_PrimaryButton__GNBmq:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ErrorLine_PrimaryButton__GNBmq:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.ErrorLine_SuccessButton__2OOzX {
  background: #46d485;
  border-color: #46d485;
}
.ErrorLine_SuccessButton__2OOzX:hover,
.ErrorLine_SuccessButton__2OOzX:focus {
  background: #46d485;
  border-color: #46d485;
}
.ErrorLine_SuccessButton__2OOzX:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.ErrorLine_ErrorLine__dwFJx {
  color: #e26a6a;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.ErrorLines_DangerColor__3eTjz {
  color: #ff8a4d;
}
.ErrorLines_WarningColor__3iTaM {
  color: #ff8a4d;
}
.ErrorLines_PrimaryColor__3xHiJ {
  color: #1c58e8;
}
.ErrorLines_SuccessColor__2Nbyf {
  color: #46d485;
}
.ErrorLines_DangerButton__SYjUI {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ErrorLines_DangerButton__SYjUI:hover,
.ErrorLines_DangerButton__SYjUI:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ErrorLines_DangerButton__SYjUI:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ErrorLines_WarningButton__1jgeb {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ErrorLines_WarningButton__1jgeb:hover,
.ErrorLines_WarningButton__1jgeb:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ErrorLines_WarningButton__1jgeb:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ErrorLines_PrimaryButton__14X0P {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ErrorLines_PrimaryButton__14X0P:hover,
.ErrorLines_PrimaryButton__14X0P:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ErrorLines_PrimaryButton__14X0P:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.ErrorLines_SuccessButton__3D9bm {
  background: #46d485;
  border-color: #46d485;
}
.ErrorLines_SuccessButton__3D9bm:hover,
.ErrorLines_SuccessButton__3D9bm:focus {
  background: #46d485;
  border-color: #46d485;
}
.ErrorLines_SuccessButton__3D9bm:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.ErrorLines_ErrorLines__2GkDz > div {
  display: flex;
  flex-direction: column;
}
.ErrorLines_ErrorLines__2GkDz .ErrorLines_Lines__JyLR2 {
  width: 100%;
}
.ErrorLines_Console__28UWY {
  display: flex;
  background-color: #000;
  padding: 10px;
  flex-direction: row;
}
.ErrorLines_Console__28UWY > div:first-child {
  max-height: 200px;
  overflow-y: scroll;
}
.ErrorLines_Console__28UWY > div > span {
  line-height: 18px;
  margin-top: 8px;
}
.ErrorLines_Console__28UWY > div > span,
.ErrorLines_Console__28UWY > div button {
  color: #fff;
}
.ErrorLines_Console__28UWY > div > button {
  padding: 0;
  display: flex;
}
.ErrorLines_Error__zVYf3 > div > span {
  color: #e26a6a;
}
.ErrorLines_Success__1tgY2 > div > span {
  color: #46d485;
}

.Upload_Checkbox__YxhNV {
  margin-bottom: 10px;
}
.Upload_MessageLines__2yjsT {
  margin-top: 10px;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.DeliveryRequestsList_DeliveryRequestsList__aGf-a .DeliveryRequestsList_PageHeader__2P9mw {
  border: 1px solid #ebedf0;
  background: white;
}
@media all and (max-width: 768px) {
  .DeliveryRequestsList_DeliveryRequestsList__aGf-a .ant-page-header-heading-extra {
    width: 100%;
  }
}
.DeliveryRequestsList_DeliveryRequestsList__aGf-a .DeliveryRequestsList_Extra__1SmlB {
  display: flex;
  align-items: center;
}
.DeliveryRequestsList_DeliveryRequestsList__aGf-a .DeliveryRequestsList_Extra__1SmlB > * + * {
  margin-left: 10px;
}
@media all and (max-width: 768px) {
  .DeliveryRequestsList_DeliveryRequestsList__aGf-a .DeliveryRequestsList_Extra__1SmlB {
    width: 100%;
    flex-direction: column;
  }
  .DeliveryRequestsList_DeliveryRequestsList__aGf-a .DeliveryRequestsList_Extra__1SmlB button {
    width: 100%;
  }
  .DeliveryRequestsList_DeliveryRequestsList__aGf-a .DeliveryRequestsList_Extra__1SmlB > div + div {
    margin-left: 0;
    margin-top: 10px;
  }
}

.DownloadAllPhotosButton_DownloadAllPhotosButton__3omuz {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DownloadAllPhotosButton_DownloadAllPhotosButton__3omuz span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DownloadAllPhotosButton_DownloadAllPhotosButton__3omuz span svg {
  font-size: 22px;
}

.DownloadCertificateButton_DownloadCertificateButton__2vEPI {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DownloadCertificateButton_DownloadCertificateButton__2vEPI span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DownloadCertificateButton_DownloadCertificateButton__2vEPI span svg {
  font-size: 22px;
}

.Photos_Extra__3eojq {
  display: flex;
}

.List_List__3OAbl .List_Photos__3BEl9 {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
}
.List_List__3OAbl .List_Photos__3BEl9 .List_Photo__7xRWB {
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.List_List__3OAbl .List_Photos__3BEl9 .List_Photo__7xRWB > div img {
  cursor: pointer;
}
.List_List__3OAbl .List_Photos__3BEl9 .List_Photo__7xRWB span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-image-preview-switch-left, .ant-image-preview-switch-right {
  width: calc(44px * 2.5);
  height: calc(44px * 2.5);
}
.ant-image-preview-switch-left > .anticon, .ant-image-preview-switch-right > .anticon {
  font-size: 80px;
}



.DataRequest_DataRequest__2fX4m .DataRequest_Badge__20q-A {
  margin-bottom: 0;
}
.DataRequest_Button__1OKmQ {
  padding: 0;
  margin-left: 15px;
}
.DataRequest_Button__1OKmQ .DataRequest_BodyButton__1O5SM {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.DataRequest_CompanyName__3lYVi {
  margin-right: 10px;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.useActions_DangerColor__1e7yT {
  color: #ff8a4d;
}
.useActions_WarningColor__180Tv {
  color: #ff8a4d;
}
.useActions_PrimaryColor__1oa-A {
  color: #1c58e8;
}
.useActions_SuccessColor__3beCr {
  color: #46d485;
}
.useActions_DangerButton__2yTD- {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.useActions_DangerButton__2yTD-:hover,
.useActions_DangerButton__2yTD-:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.useActions_DangerButton__2yTD-:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.useActions_WarningButton__3jkUu {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.useActions_WarningButton__3jkUu:hover,
.useActions_WarningButton__3jkUu:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.useActions_WarningButton__3jkUu:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.useActions_PrimaryButton__FaHAs {
  background: #1c58e8;
  border-color: #1c58e8;
}
.useActions_PrimaryButton__FaHAs:hover,
.useActions_PrimaryButton__FaHAs:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.useActions_PrimaryButton__FaHAs:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.useActions_SuccessButton__3zUet {
  background: #46d485;
  border-color: #46d485;
}
.useActions_SuccessButton__3zUet:hover,
.useActions_SuccessButton__3zUet:focus {
  background: #46d485;
  border-color: #46d485;
}
.useActions_SuccessButton__3zUet:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Request_DangerColor__2TvDT {
  color: #ff8a4d;
}
.Request_WarningColor__2oX5k {
  color: #ff8a4d;
}
.Request_PrimaryColor__3yJcq {
  color: #1c58e8;
}
.Request_SuccessColor__2Qzti {
  color: #46d485;
}
.Request_DangerButton__2fb7l {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Request_DangerButton__2fb7l:hover,
.Request_DangerButton__2fb7l:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Request_DangerButton__2fb7l:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Request_WarningButton__31Z5j {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Request_WarningButton__31Z5j:hover,
.Request_WarningButton__31Z5j:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Request_WarningButton__31Z5j:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Request_PrimaryButton__1vEDE {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Request_PrimaryButton__1vEDE:hover,
.Request_PrimaryButton__1vEDE:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Request_PrimaryButton__1vEDE:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Request_SuccessButton__2C03v {
  background: #46d485;
  border-color: #46d485;
}
.Request_SuccessButton__2C03v:hover,
.Request_SuccessButton__2C03v:focus {
  background: #46d485;
  border-color: #46d485;
}
.Request_SuccessButton__2C03v:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Request_Request__3Em_e > div + div {
  margin-top: 10px;
}
.Request_Request__3Em_e .Request_Status__o6HE3 {
  display: flex;
}
.Request_Request__3Em_e .Request_Status__o6HE3 > span {
  margin-right: 0;
  text-align: center;
}
.Request_Request__3Em_e .Request_Status__o6HE3 > span + span {
  margin-left: 8px;
}
@media all and (max-width: 768px) {
  .Request_Request__3Em_e .Request_Status__o6HE3 {
    flex-direction: column-reverse;
  }
}
.Request_Title__2xQrq {
  display: flex;
  flex-direction: row;
}
.Request_Title__2xQrq .Request_SubTilte__lLydn {
  height: 14px;
}
@media all and (max-width: 768px) {
  .Request_Title__2xQrq .Request_SubTilte__lLydn {
    height: unset;
  }
}
.Request_Title__2xQrq .Request_SubTilte__lLydn > span {
  margin-left: 10px;
  font-size: 12px;
}
@media all and (max-width: 768px) {
  .Request_Title__2xQrq .Request_SubTilte__lLydn > span {
    margin-left: 0;
  }
}
@media all and (max-width: 768px) {
  .Request_Title__2xQrq {
    flex-direction: column;
  }
}

.Address_Address__1DmKJ {
  display: flex;
  flex-direction: column;
}
.Address_Address__1DmKJ .Address_Map__2WAYb {
  flex: 2 1;
}
.Address_Address__1DmKJ .ant-card {
  flex: 1 1;
}
.Address_Address__1DmKJ .Address_Status__1gT0n > span {
  margin-right: 0;
}
.Address_Address__1DmKJ .Address_Status__1gT0n > span + span {
  margin-left: 8px;
}
.Address_Address__1DmKJ .Address_FailureReasons__22SB2 {
  margin-top: 15px;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Documents_DangerColor__3-yrw {
  color: #ff8a4d;
}
.Documents_WarningColor__2V2Pt {
  color: #ff8a4d;
}
.Documents_PrimaryColor__32xsb {
  color: #1c58e8;
}
.Documents_SuccessColor__1JB4- {
  color: #46d485;
}
.Documents_DangerButton__mKaw4 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Documents_DangerButton__mKaw4:hover,
.Documents_DangerButton__mKaw4:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Documents_DangerButton__mKaw4:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Documents_WarningButton__26oul {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Documents_WarningButton__26oul:hover,
.Documents_WarningButton__26oul:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Documents_WarningButton__26oul:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Documents_PrimaryButton__2p4Uk {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Documents_PrimaryButton__2p4Uk:hover,
.Documents_PrimaryButton__2p4Uk:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Documents_PrimaryButton__2p4Uk:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Documents_SuccessButton__25Z3Y {
  background: #46d485;
  border-color: #46d485;
}
.Documents_SuccessButton__25Z3Y:hover,
.Documents_SuccessButton__25Z3Y:focus {
  background: #46d485;
  border-color: #46d485;
}
.Documents_SuccessButton__25Z3Y:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Documents_Documents__upI1M .Documents_Dragger__2wXVu {
  margin-top: 1rem;
}

.Item_Item__2Kzei {
  display: flex;
  flex-direction: column;
}
.Item_Item__2Kzei .Item_Map__2Fgk7 {
  flex: 2 1;
}
.Item_Item__2Kzei .ant-card {
  flex: 1 1;
}
.Item_Item__2Kzei .Item_Status__2eUv3 > span {
  margin-right: 0;
}
.Item_Item__2Kzei .Item_Status__2eUv3 > span + span {
  margin-left: 8px;
}
.Item_Item__2Kzei .Item_FailureReasons__2UYtl {
  margin-top: 15px;
}

.List_List__3xu4a {
  width: 100%;
}

.HistoryDelivery_HistoryDelivery__3nsxI {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.HistoryDelivery_HistoryDelivery__3nsxI .HistoryDelivery_OpenButton__3EgXT {
  display: flex;
  align-items: center;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Button__jfQ6v {
  width: 100%;
  display: flex;
  justify-content: center;
}
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Button__jfQ6v button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Failed__3G1oN {
  color: #e26a6a;
}
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Terminated__3wyrz,
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_InProgress__1g4Lh,
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_In__WIVnz {
  color: #46d485;
}
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_In__WIVnz,
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Out__X6CbW {
  font-size: 21px;
}
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Transfer__2pgyI,
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Pending__3M-vZ {
  color: #ff8a4d;
}
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Paused__tcag2,
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_PendingAnswer__2NVpy,
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Out__X6CbW {
  color: #b270dc;
}
.PhoneCallHistory_PhoneCallHistory__17eRU .PhoneCallHistory_Call__ErLrs {
  display: flex;
  align-items: center;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Content_DangerColor__1IRRe {
  color: #ff8a4d;
}
.Content_WarningColor__1iQwB {
  color: #ff8a4d;
}
.Content_PrimaryColor__3dlub {
  color: #1c58e8;
}
.Content_SuccessColor__1R8N- {
  color: #46d485;
}
.Content_DangerButton__1si2f {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Content_DangerButton__1si2f:hover,
.Content_DangerButton__1si2f:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Content_DangerButton__1si2f:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Content_WarningButton__2snbL {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Content_WarningButton__2snbL:hover,
.Content_WarningButton__2snbL:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Content_WarningButton__2snbL:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Content_PrimaryButton__2Hitp {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Content_PrimaryButton__2Hitp:hover,
.Content_PrimaryButton__2Hitp:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Content_PrimaryButton__2Hitp:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Content_SuccessButton__2VdcM {
  background: #46d485;
  border-color: #46d485;
}
.Content_SuccessButton__2VdcM:hover,
.Content_SuccessButton__2VdcM:focus {
  background: #46d485;
  border-color: #46d485;
}
.Content_SuccessButton__2VdcM:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Content_Content__38o33 {
  position: relative;
}

.Comments_Comments__w7qHy {
  padding: 10px;
  max-height: 400px;
  overflow: auto;
}
.Comments_Comments__w7qHy .Comments_Empty__JNHw_ {
  display: flex;
  justify-content: center;
}
.Comments_Comments__w7qHy .Comments_Empty__JNHw_ > span {
  background-color: #a7a1a136;
  padding: 2px;
  font-size: 12px;
  border-radius: 8px;
  margin: 20px;
}
.Comments_Comments__w7qHy > * + * {
  margin-top: 10px;
}
.Comments_Message__3nhEf .Comments_Wrapper__3IcQA {
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.Comments_Message__3nhEf .Comments_Wrapper__3IcQA .Comments_Reason__3q_cU {
  width: max-content;
  padding-bottom: 5px;
}
.Comments_Message__3nhEf .Comments_Wrapper__3IcQA .Comments_Reason__3q_cU > span {
  border-radius: 5px;
  padding: 0 2px;
  font-size: 12px;
}
.Comments_Message__3nhEf .Comments_Wrapper__3IcQA .Comments_Content__1-mtW {
  border: 0px;
  border-radius: 20px;
  width: max-content;
  max-width: 80%;
  min-width: 150px;
  padding: 1em 1.5em;
  padding-bottom: 3px;
  box-shadow: 0px 0px 24px -7px rgba(34, 60, 80, 0.21);
}
.Comments_Message__3nhEf .Comments_Wrapper__3IcQA .Comments_Content__1-mtW .Comments_Text__1705o {
  color: #000;
  white-space: pre;
  white-space: pre-line;
}
.Comments_Message__3nhEf .Comments_Wrapper__3IcQA .Comments_Content__1-mtW .Comments_Actions__2ORwl {
  padding-top: 7px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.Comments_Message__3nhEf .Comments_Wrapper__3IcQA .Comments_Content__1-mtW .Comments_Actions__2ORwl > * + * {
  margin-left: 20px;
}
.Comments_Message__3nhEf .Comments_Wrapper__3IcQA .Comments_Content__1-mtW .Comments_Actions__2ORwl > * + * > * + * {
  margin-left: 5px;
}
.Comments_FrMessage__-aoTd {
  align-items: flex-end;
}
.Comments_FrMessage__-aoTd .Comments_Content__1-mtW {
  margin-left: 50px;
  background-color: #eeffde;
}
.Comments_FrMessage__-aoTd .Comments_Reason__3q_cU {
  margin-right: 10px;
}
.Comments_PartnerMessage__276Gw {
  align-items: flex-start;
}
.Comments_PartnerMessage__276Gw .Comments_Content__1-mtW {
  margin-right: 50px;
  background-color: #b9dcee;
}
.Comments_PartnerMessage__276Gw .Comments_Reason__3q_cU {
  margin-left: 10px;
}

.ShowDate_ShowDate__3IvX- {
  white-space: nowrap;
}

.TaskCommentsWidget_TaskCommentsWidget__1pKt2 {
  border-radius: 15px !important;
}
.TaskCommentsWidget_TaskCommentsWidget__1pKt2 .ant-card-body {
  padding: 0 !important;
}

.CreateComment_CreateComment__YaGYm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.CreateComment_CreateComment__YaGYm button {
  width: min-content;
}
.CreateComment_CreateComment__YaGYm .CreateComment_TextArea__N1XKh {
  width: 100%;
  border-radius: 0 0 15px 15px;
  display: flex;
  padding: 0;
}
.CreateComment_CreateComment__YaGYm .CreateComment_TextArea__N1XKh > textarea {
  padding: 10px;
  resize: none;
  border: 0;
  background: transparent;
  outline: none;
  flex: 1 1;
}
.CreateComment_CreateComment__YaGYm .CreateComment_TextArea__N1XKh button {
  cursor: pointer;
  background: transparent;
  border: 0 solid transparent;
}
.CreateComment_CreateComment__YaGYm .CreateComment_TextArea__N1XKh .CreateComment_Actions__3T17x {
  padding: 10px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
}
.CreateComment_CreateComment__YaGYm .CreateComment_TextArea__N1XKh .CreateComment_Actions__3T17x .CreateComment_Buttons__1bgWf {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CreateComment_CreateComment__YaGYm .ant-input:focus {
  box-shadow: unset;
}

.DeliveryRequestsShow_DeliveryRequestsShow__184Fq {
  height: 100%;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.AddDeliveris_DangerColor__23IeB {
  color: #ff8a4d;
}
.AddDeliveris_WarningColor__2G8n0 {
  color: #ff8a4d;
}
.AddDeliveris_PrimaryColor__YoCBA {
  color: #1c58e8;
}
.AddDeliveris_SuccessColor__3pUqT {
  color: #46d485;
}
.AddDeliveris_DangerButton__3xMzc {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AddDeliveris_DangerButton__3xMzc:hover,
.AddDeliveris_DangerButton__3xMzc:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AddDeliveris_DangerButton__3xMzc:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.AddDeliveris_WarningButton__3WC7A {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AddDeliveris_WarningButton__3WC7A:hover,
.AddDeliveris_WarningButton__3WC7A:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AddDeliveris_WarningButton__3WC7A:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.AddDeliveris_PrimaryButton__4ljGP {
  background: #1c58e8;
  border-color: #1c58e8;
}
.AddDeliveris_PrimaryButton__4ljGP:hover,
.AddDeliveris_PrimaryButton__4ljGP:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.AddDeliveris_PrimaryButton__4ljGP:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.AddDeliveris_SuccessButton__wMmU_ {
  background: #46d485;
  border-color: #46d485;
}
.AddDeliveris_SuccessButton__wMmU_:hover,
.AddDeliveris_SuccessButton__wMmU_:focus {
  background: #46d485;
  border-color: #46d485;
}
.AddDeliveris_SuccessButton__wMmU_:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.AddDeliveris_AddDeliveris__1Obpe {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.AddDeliveris_AddDeliveris__1Obpe .ant-card-body {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AddDeliveris_AddDeliveris__1Obpe .AddDeliveris_Button__OIHFu {
  height: 100%;
  width: 100%;
  padding: 0;
}
.AddDeliveris_AddDeliveris__1Obpe .AddDeliveris_Button__OIHFu span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc((70vw - 5px * 11) / 12 - 10px - 16px);
}
@media all and (max-width: 1200px) {
  .AddDeliveris_AddDeliveris__1Obpe .AddDeliveris_Button__OIHFu span {
    font-size: calc((100vw - 5px * 7) / 8 - 20px - 8px);
  }
}
@media all and (max-width: 768px) {
  .AddDeliveris_AddDeliveris__1Obpe .AddDeliveris_Button__OIHFu span {
    font-size: calc((100vw - 5px * 3) / 2 - 20px - 4px);
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.BillingDocuments_DangerColor__2IdUe {
  color: #ff8a4d;
}
.BillingDocuments_WarningColor__D9EjR {
  color: #ff8a4d;
}
.BillingDocuments_PrimaryColor__1zgS7 {
  color: #1c58e8;
}
.BillingDocuments_SuccessColor__1Eq62 {
  color: #46d485;
}
.BillingDocuments_DangerButton__2GBks {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.BillingDocuments_DangerButton__2GBks:hover,
.BillingDocuments_DangerButton__2GBks:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.BillingDocuments_DangerButton__2GBks:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.BillingDocuments_WarningButton__1dViL {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.BillingDocuments_WarningButton__1dViL:hover,
.BillingDocuments_WarningButton__1dViL:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.BillingDocuments_WarningButton__1dViL:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.BillingDocuments_PrimaryButton__2QpBQ {
  background: #1c58e8;
  border-color: #1c58e8;
}
.BillingDocuments_PrimaryButton__2QpBQ:hover,
.BillingDocuments_PrimaryButton__2QpBQ:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.BillingDocuments_PrimaryButton__2QpBQ:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.BillingDocuments_SuccessButton__133v1 {
  background: #46d485;
  border-color: #46d485;
}
.BillingDocuments_SuccessButton__133v1:hover,
.BillingDocuments_SuccessButton__133v1:focus {
  background: #46d485;
  border-color: #46d485;
}
.BillingDocuments_SuccessButton__133v1:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.BillingDocuments_BillingDocuments__med_Q {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.BillingDocuments_BillingDocuments__med_Q .ant-card-body {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BillingDocuments_BillingDocuments__med_Q .BillingDocuments_Button__rm5sj {
  height: 100%;
  width: 100%;
  padding: 0;
}
.BillingDocuments_BillingDocuments__med_Q .BillingDocuments_Button__rm5sj span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc((70vw - 5px * 11) / 12 - 10px - 16px);
}
@media all and (max-width: 1200px) {
  .BillingDocuments_BillingDocuments__med_Q .BillingDocuments_Button__rm5sj span {
    font-size: calc((100vw - 5px * 7) / 8 - 20px - 8px);
  }
}
@media all and (max-width: 768px) {
  .BillingDocuments_BillingDocuments__med_Q .BillingDocuments_Button__rm5sj span {
    font-size: calc((100vw - 5px * 3) / 2 - 20px - 4px);
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.FeedBack_DangerColor__1eBFs {
  color: #ff8a4d;
}
.FeedBack_WarningColor__377IW {
  color: #ff8a4d;
}
.FeedBack_PrimaryColor__ARAD- {
  color: #1c58e8;
}
.FeedBack_SuccessColor__WQaeW {
  color: #46d485;
}
.FeedBack_DangerButton__2SCno {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.FeedBack_DangerButton__2SCno:hover,
.FeedBack_DangerButton__2SCno:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.FeedBack_DangerButton__2SCno:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.FeedBack_WarningButton__Edo-j {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.FeedBack_WarningButton__Edo-j:hover,
.FeedBack_WarningButton__Edo-j:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.FeedBack_WarningButton__Edo-j:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.FeedBack_PrimaryButton__1tEMN {
  background: #1c58e8;
  border-color: #1c58e8;
}
.FeedBack_PrimaryButton__1tEMN:hover,
.FeedBack_PrimaryButton__1tEMN:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.FeedBack_PrimaryButton__1tEMN:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.FeedBack_SuccessButton__zEPCC {
  background: #46d485;
  border-color: #46d485;
}
.FeedBack_SuccessButton__zEPCC:hover,
.FeedBack_SuccessButton__zEPCC:focus {
  background: #46d485;
  border-color: #46d485;
}
.FeedBack_SuccessButton__zEPCC:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.FeedBack_FeedBack__37K5f {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.FeedBack_FeedBack__37K5f .ant-card-body {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.FeedBack_FeedBack__37K5f .FeedBack_Button__2UHj1 {
  height: 100%;
  width: 100%;
  padding: 0;
}
.FeedBack_FeedBack__37K5f .FeedBack_Button__2UHj1 span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc((70vw - 5px * 11) / 12 - 10px - 16px);
}
@media all and (max-width: 1200px) {
  .FeedBack_FeedBack__37K5f .FeedBack_Button__2UHj1 span {
    font-size: calc((100vw - 5px * 7) / 8 - 20px - 8px);
  }
}
@media all and (max-width: 768px) {
  .FeedBack_FeedBack__37K5f .FeedBack_Button__2UHj1 span {
    font-size: calc((100vw - 5px * 3) / 2 - 20px - 4px);
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.ListDeliveris_DangerColor__-5PiX {
  color: #ff8a4d;
}
.ListDeliveris_WarningColor__rE4vs {
  color: #ff8a4d;
}
.ListDeliveris_PrimaryColor__3g84L {
  color: #1c58e8;
}
.ListDeliveris_SuccessColor__1NMuk {
  color: #46d485;
}
.ListDeliveris_DangerButton__3NkT9 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ListDeliveris_DangerButton__3NkT9:hover,
.ListDeliveris_DangerButton__3NkT9:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ListDeliveris_DangerButton__3NkT9:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ListDeliveris_WarningButton__iZtHN {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ListDeliveris_WarningButton__iZtHN:hover,
.ListDeliveris_WarningButton__iZtHN:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ListDeliveris_WarningButton__iZtHN:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ListDeliveris_PrimaryButton__3YjDG {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ListDeliveris_PrimaryButton__3YjDG:hover,
.ListDeliveris_PrimaryButton__3YjDG:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ListDeliveris_PrimaryButton__3YjDG:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.ListDeliveris_SuccessButton__ilLpu {
  background: #46d485;
  border-color: #46d485;
}
.ListDeliveris_SuccessButton__ilLpu:hover,
.ListDeliveris_SuccessButton__ilLpu:focus {
  background: #46d485;
  border-color: #46d485;
}
.ListDeliveris_SuccessButton__ilLpu:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.ListDeliveris_ListDeliveris__TmqrB {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.ListDeliveris_ListDeliveris__TmqrB .ant-card-body {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ListDeliveris_ListDeliveris__TmqrB .ListDeliveris_Button__3W8SY {
  height: 100%;
  width: 100%;
  padding: 0;
}
.ListDeliveris_ListDeliveris__TmqrB .ListDeliveris_Button__3W8SY span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc((70vw - 5px * 11) / 12 - 10px - 16px);
}
@media all and (max-width: 1200px) {
  .ListDeliveris_ListDeliveris__TmqrB .ListDeliveris_Button__3W8SY span {
    font-size: calc((100vw - 5px * 7) / 8 - 20px - 8px);
  }
}
@media all and (max-width: 768px) {
  .ListDeliveris_ListDeliveris__TmqrB .ListDeliveris_Button__3W8SY span {
    font-size: calc((100vw - 5px * 3) / 2 - 20px - 4px);
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Profile_DangerColor__n3q3H {
  color: #ff8a4d;
}
.Profile_WarningColor__1RXLW {
  color: #ff8a4d;
}
.Profile_PrimaryColor__GjAMs {
  color: #1c58e8;
}
.Profile_SuccessColor__QUUiK {
  color: #46d485;
}
.Profile_DangerButton__ASNUM {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Profile_DangerButton__ASNUM:hover,
.Profile_DangerButton__ASNUM:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Profile_DangerButton__ASNUM:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Profile_WarningButton__3B_5I {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Profile_WarningButton__3B_5I:hover,
.Profile_WarningButton__3B_5I:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Profile_WarningButton__3B_5I:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Profile_PrimaryButton__Nhkrs {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Profile_PrimaryButton__Nhkrs:hover,
.Profile_PrimaryButton__Nhkrs:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Profile_PrimaryButton__Nhkrs:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Profile_SuccessButton__3apJy {
  background: #46d485;
  border-color: #46d485;
}
.Profile_SuccessButton__3apJy:hover,
.Profile_SuccessButton__3apJy:focus {
  background: #46d485;
  border-color: #46d485;
}
.Profile_SuccessButton__3apJy:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Profile_Profile__1qgtk {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.Profile_Profile__1qgtk .ant-card-body {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Profile_Profile__1qgtk .Profile_Button__MBqEq {
  height: 100%;
  width: 100%;
  padding: 0;
}
.Profile_Profile__1qgtk .Profile_Button__MBqEq span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc((70vw - 5px * 11) / 12 - 10px - 16px);
}
@media all and (max-width: 1200px) {
  .Profile_Profile__1qgtk .Profile_Button__MBqEq span {
    font-size: calc((100vw - 5px * 7) / 8 - 20px - 8px);
  }
}
@media all and (max-width: 768px) {
  .Profile_Profile__1qgtk .Profile_Button__MBqEq span {
    font-size: calc((100vw - 5px * 3) / 2 - 20px - 4px);
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Reporting_DangerColor__3WQj5 {
  color: #ff8a4d;
}
.Reporting_WarningColor__3TJPY {
  color: #ff8a4d;
}
.Reporting_PrimaryColor__2-d8i {
  color: #1c58e8;
}
.Reporting_SuccessColor__MYS_8 {
  color: #46d485;
}
.Reporting_DangerButton__hUVEv {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Reporting_DangerButton__hUVEv:hover,
.Reporting_DangerButton__hUVEv:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Reporting_DangerButton__hUVEv:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Reporting_WarningButton__1VWh1 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Reporting_WarningButton__1VWh1:hover,
.Reporting_WarningButton__1VWh1:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Reporting_WarningButton__1VWh1:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Reporting_PrimaryButton__25xqO {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Reporting_PrimaryButton__25xqO:hover,
.Reporting_PrimaryButton__25xqO:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Reporting_PrimaryButton__25xqO:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Reporting_SuccessButton__3iSKF {
  background: #46d485;
  border-color: #46d485;
}
.Reporting_SuccessButton__3iSKF:hover,
.Reporting_SuccessButton__3iSKF:focus {
  background: #46d485;
  border-color: #46d485;
}
.Reporting_SuccessButton__3iSKF:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Reporting_Reporting__1mziU {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.Reporting_Reporting__1mziU .ant-card-body {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Reporting_Reporting__1mziU .Reporting_Button__3obeT {
  height: 100%;
  width: 100%;
  padding: 0;
}
.Reporting_Reporting__1mziU .Reporting_Button__3obeT span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc((70vw - 5px * 11) / 12 - 10px - 16px);
}
@media all and (max-width: 1200px) {
  .Reporting_Reporting__1mziU .Reporting_Button__3obeT span {
    font-size: calc((100vw - 5px * 7) / 8 - 20px - 8px);
  }
}
@media all and (max-width: 768px) {
  .Reporting_Reporting__1mziU .Reporting_Button__3obeT span {
    font-size: calc((100vw - 5px * 3) / 2 - 20px - 4px);
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.UsersMenu_DangerColor__2b-y5 {
  color: #ff8a4d;
}
.UsersMenu_WarningColor__1fX_u {
  color: #ff8a4d;
}
.UsersMenu_PrimaryColor__3ARh2 {
  color: #1c58e8;
}
.UsersMenu_SuccessColor__2Suvn {
  color: #46d485;
}
.UsersMenu_DangerButton__3YmSG {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.UsersMenu_DangerButton__3YmSG:hover,
.UsersMenu_DangerButton__3YmSG:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.UsersMenu_DangerButton__3YmSG:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.UsersMenu_WarningButton__3weWJ {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.UsersMenu_WarningButton__3weWJ:hover,
.UsersMenu_WarningButton__3weWJ:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.UsersMenu_WarningButton__3weWJ:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.UsersMenu_PrimaryButton__2SsGo {
  background: #1c58e8;
  border-color: #1c58e8;
}
.UsersMenu_PrimaryButton__2SsGo:hover,
.UsersMenu_PrimaryButton__2SsGo:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.UsersMenu_PrimaryButton__2SsGo:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.UsersMenu_SuccessButton__16bA1 {
  background: #46d485;
  border-color: #46d485;
}
.UsersMenu_SuccessButton__16bA1:hover,
.UsersMenu_SuccessButton__16bA1:focus {
  background: #46d485;
  border-color: #46d485;
}
.UsersMenu_SuccessButton__16bA1:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.UsersMenu_UsersMenu__25lOf {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.UsersMenu_UsersMenu__25lOf .ant-card-body {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.UsersMenu_UsersMenu__25lOf .UsersMenu_Button__3Gqo_ {
  height: 100%;
  width: 100%;
  padding: 0;
}
.UsersMenu_UsersMenu__25lOf .UsersMenu_Button__3Gqo_ span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc((70vw - 5px * 11) / 12 - 10px - 16px);
}
@media all and (max-width: 1200px) {
  .UsersMenu_UsersMenu__25lOf .UsersMenu_Button__3Gqo_ span {
    font-size: calc((100vw - 5px * 7) / 8 - 20px - 8px);
  }
}
@media all and (max-width: 768px) {
  .UsersMenu_UsersMenu__25lOf .UsersMenu_Button__3Gqo_ span {
    font-size: calc((100vw - 5px * 3) / 2 - 20px - 4px);
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Dashboard_DangerColor__3_-Wd {
  color: #ff8a4d;
}
.Dashboard_WarningColor__3TMaA {
  color: #ff8a4d;
}
.Dashboard_PrimaryColor__2qt-w {
  color: #1c58e8;
}
.Dashboard_SuccessColor__HN3uq {
  color: #46d485;
}
.Dashboard_DangerButton__3b8Zg {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Dashboard_DangerButton__3b8Zg:hover,
.Dashboard_DangerButton__3b8Zg:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Dashboard_DangerButton__3b8Zg:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Dashboard_WarningButton__2n8i9 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Dashboard_WarningButton__2n8i9:hover,
.Dashboard_WarningButton__2n8i9:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Dashboard_WarningButton__2n8i9:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Dashboard_PrimaryButton__3JQop {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Dashboard_PrimaryButton__3JQop:hover,
.Dashboard_PrimaryButton__3JQop:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Dashboard_PrimaryButton__3JQop:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Dashboard_SuccessButton__2O-_t {
  background: #46d485;
  border-color: #46d485;
}
.Dashboard_SuccessButton__2O-_t:hover,
.Dashboard_SuccessButton__2O-_t:focus {
  background: #46d485;
  border-color: #46d485;
}
.Dashboard_SuccessButton__2O-_t:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Dashboard_Dashboard__OLICk {
  display: flex;
}
.Dashboard_Dashboard__OLICk .Dashboard_Menu__bjSFl {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(1, calc(70vw / 12));
  grid-template-rows: repeat(7, calc((70vw - 5px * 11) / 12));
  margin-right: 5px;
}
@media all and (max-width: 1200px) {
  .Dashboard_Dashboard__OLICk .Dashboard_Menu__bjSFl {
    grid-template-columns: repeat(1, calc(100vw / 8));
    grid-template-rows: repeat(7, calc((100vw - 5px * 7 - 10px * 2) / 8));
  }
}
@media all and (max-width: 768px) {
  .Dashboard_Dashboard__OLICk .Dashboard_Menu__bjSFl {
    display: none;
  }
}
.Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU {
  display: grid;
  grid-gap: 5px;
  width: 100%;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(7, calc((70vw - 5px * 11) / 12));
  grid-template-areas: 'Products     Products     Products     Products     Products     CreatedData   CreatedData  CreatedData  StatusBar StatusBar StatusBar' 'Products     Products     Products     Products     Products     CreatedData   CreatedData  CreatedData  StatusBar StatusBar StatusBar' 'Products     Products     Products     Products     Products     Users         Users        Users        StatusBar StatusBar StatusBar' 'Products     Products     Products     Products     Products     Users         Users        Users        StatusBar StatusBar StatusBar' 'DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate  DeliveryDate DeliveryDate Payments  Payments  Payments' 'DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate  DeliveryDate DeliveryDate Payments  Payments  Payments' 'DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate  DeliveryDate DeliveryDate -         -         -' 'DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate  DeliveryDate DeliveryDate -         -         -';
}
@media all and (max-width: 1200px) {
  .Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(7, calc((100vw - 5px * 7 - 10px * 2) / 8));
    grid-template-areas: 'Products      Products    Products     Products     Products     StatusBar    StatusBar    StatusBar' 'Products      Products    Products     Products     Products     StatusBar    StatusBar    StatusBar' 'Products      Products    Products     Products     Products     StatusBar    StatusBar    StatusBar' 'Products      Products    Products     Products     Products     StatusBar    StatusBar    StatusBar' 'CreatedData   CreatedData DeliveryDate DeliveryDate DeliveryDate Payments     Payments     Payments' 'CreatedData   CreatedData DeliveryDate DeliveryDate DeliveryDate Payments     Payments     Payments' 'Users         Users       DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate' 'Users         Users       DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate';
  }
}
@media all and (max-width: 768px) {
  .Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, calc((100vw - 5px * 3) / 2));
    grid-template-areas: 'Products     Products' 'Products     Products' 'Users        Users' 'StatusBar    StatusBar' 'StatusBar    StatusBar' 'CreatedData  CreatedData' 'Payments     Payments' 'DeliveryDate DeliveryDate';
  }
}
.Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU .Dashboard_DeliveryDate__2hWfy {
  grid-area: DeliveryDate;
}
.Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU .Dashboard_StatusBar__3diAu {
  grid-area: StatusBar;
}
.Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU .Dashboard_Payments__3I6-b {
  grid-area: Payments;
}
.Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU .Dashboard_CreatedData__QEcm4 {
  grid-area: CreatedData;
}
.Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU .Dashboard_Products__3jgMh {
  grid-area: Products;
}
.Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU .Dashboard_Users__3iQ4G {
  grid-area: Users;
}
.Dashboard_Dashboard__OLICk .Dashboard_Tiles__2ZaLU .Dashboard_Buttons__TICxG {
  grid-area: Buttons;
}
.Dashboard_Dashboard__OLICk .Dashboard_Disabled__2MfDx {
  pointer-events: none;
  opacity: 0.4;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.CreatedData_DangerColor__1L-c- {
  color: #ff8a4d;
}
.CreatedData_WarningColor__3xNhl {
  color: #ff8a4d;
}
.CreatedData_PrimaryColor__1bSUX {
  color: #1c58e8;
}
.CreatedData_SuccessColor__3i_bQ {
  color: #46d485;
}
.CreatedData_DangerButton__Xjblp {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CreatedData_DangerButton__Xjblp:hover,
.CreatedData_DangerButton__Xjblp:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CreatedData_DangerButton__Xjblp:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.CreatedData_WarningButton__e4X9o {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CreatedData_WarningButton__e4X9o:hover,
.CreatedData_WarningButton__e4X9o:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CreatedData_WarningButton__e4X9o:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.CreatedData_PrimaryButton__2YtOB {
  background: #1c58e8;
  border-color: #1c58e8;
}
.CreatedData_PrimaryButton__2YtOB:hover,
.CreatedData_PrimaryButton__2YtOB:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.CreatedData_PrimaryButton__2YtOB:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.CreatedData_SuccessButton__24imM {
  background: #46d485;
  border-color: #46d485;
}
.CreatedData_SuccessButton__24imM:hover,
.CreatedData_SuccessButton__24imM:focus {
  background: #46d485;
  border-color: #46d485;
}
.CreatedData_SuccessButton__24imM:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.CreatedData_CreatedData__3zCsL {
  height: 100%;
}
.CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc((70vw - 5px * 11) / 12 * 2 - 58px - 48px);
}
@media all and (max-width: 1200px) {
  .CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 {
    height: calc((100vw - 5px * 7) / 8 * 2 - 58px - 48px);
  }
}
@media all and (max-width: 768px) {
  .CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 {
    height: calc((100vw - 5px * 3) / 2 - 58px - 48px);
  }
}
.CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 .CreatedData_Item__1dHeB {
  display: flex;
  align-items: center;
}
.CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 .CreatedData_Item__1dHeB > :first-child {
  flex: 1 1;
  line-height: 1;
  font-size: calc(((70vw - 5px * 11) / 12 * 2 - 58px - 48px) / 5);
}
@media all and (max-width: 1200px) {
  .CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 .CreatedData_Item__1dHeB > :first-child {
    font-size: calc(((100vw - 5px * 7) / 8 * 2 - 58px - 48px) / 5);
  }
}
@media all and (max-width: 768px) {
  .CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 .CreatedData_Item__1dHeB > :first-child {
    font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 5);
  }
}
.CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 .CreatedData_Item__1dHeB > :last-child {
  padding-bottom: 0;
  line-height: 1;
  font-size: calc(((70vw - 5px * 11) / 12 * 2 - 58px - 48px) / 3);
}
@media all and (max-width: 1200px) {
  .CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 .CreatedData_Item__1dHeB > :last-child {
    font-size: calc(((100vw - 5px * 7) / 8 * 2 - 58px - 48px) / 3);
  }
}
@media all and (max-width: 768px) {
  .CreatedData_CreatedData__3zCsL .CreatedData_Body__1m6Z0 .CreatedData_Item__1dHeB > :last-child {
    font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 3);
  }
}

.DeliveryDate_Hint__sfvD_ {
  background-color: #fff;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Payments_DangerColor__2BGFW {
  color: #ff8a4d;
}
.Payments_WarningColor__1Yr2E {
  color: #ff8a4d;
}
.Payments_PrimaryColor__3p23J {
  color: #1c58e8;
}
.Payments_SuccessColor__3iuNF {
  color: #46d485;
}
.Payments_DangerButton__KFs2S {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Payments_DangerButton__KFs2S:hover,
.Payments_DangerButton__KFs2S:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Payments_DangerButton__KFs2S:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Payments_WarningButton__22neK {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Payments_WarningButton__22neK:hover,
.Payments_WarningButton__22neK:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Payments_WarningButton__22neK:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Payments_PrimaryButton__1tCYA {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Payments_PrimaryButton__1tCYA:hover,
.Payments_PrimaryButton__1tCYA:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Payments_PrimaryButton__1tCYA:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Payments_SuccessButton__3jXLa {
  background: #46d485;
  border-color: #46d485;
}
.Payments_SuccessButton__3jXLa:hover,
.Payments_SuccessButton__3jXLa:focus {
  background: #46d485;
  border-color: #46d485;
}
.Payments_SuccessButton__3jXLa:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Payments_Payments__3q-IB {
  height: 100%;
}
.Payments_Payments__3q-IB .Payments_Body__2FGUj {
  height: calc((70vw - 5px * 11) / 12 * 4 - 58px - 48px);
}
@media all and (max-width: 1200px) {
  .Payments_Payments__3q-IB .Payments_Body__2FGUj {
    height: calc((100vw - 5px * 7) / 8 * 4 - 58px - 48px);
  }
}
@media all and (max-width: 768px) {
  .Payments_Payments__3q-IB .Payments_Body__2FGUj {
    height: calc((100vw - 5px * 3) / 2 - 58px - 48px);
  }
}
.Payments_Payments__3q-IB .Payments_Body__2FGUj .Payments_Item__AQT86 {
  display: flex;
  align-items: center;
}
.Payments_Payments__3q-IB .Payments_Body__2FGUj .Payments_Item__AQT86 > :first-child {
  flex: 1 1;
  line-height: 1;
  font-size: calc(((70vw - 5px * 11) / 12 * 2 - 58px - 48px) / 5);
}
@media all and (max-width: 1200px) {
  .Payments_Payments__3q-IB .Payments_Body__2FGUj .Payments_Item__AQT86 > :first-child {
    font-size: calc(((100vw - 5px * 7) / 8 * 2 - 58px - 48px) / 5);
  }
}
@media all and (max-width: 768px) {
  .Payments_Payments__3q-IB .Payments_Body__2FGUj .Payments_Item__AQT86 > :first-child {
    font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 5);
  }
}
.Payments_Payments__3q-IB .Payments_Body__2FGUj .Payments_Item__AQT86 > :last-child {
  padding-bottom: 0;
  line-height: 1;
  font-size: calc(((70vw - 5px * 11) / 12 * 2 - 58px - 48px) / 3);
}
@media all and (max-width: 1200px) {
  .Payments_Payments__3q-IB .Payments_Body__2FGUj .Payments_Item__AQT86 > :last-child {
    font-size: calc(((100vw - 5px * 7) / 8 * 2 - 58px - 48px) / 3);
  }
}
@media all and (max-width: 768px) {
  .Payments_Payments__3q-IB .Payments_Body__2FGUj .Payments_Item__AQT86 > :last-child {
    font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 3);
  }
}
.Payments_Payments__3q-IB .Payments_Body__2FGUj .Payments_Warning__24E5y {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Products_DangerColor__3Ca23 {
  color: #ff8a4d;
}
.Products_WarningColor__2r_FI {
  color: #ff8a4d;
}
.Products_PrimaryColor__1FqCr {
  color: #1c58e8;
}
.Products_SuccessColor__30e8b {
  color: #46d485;
}
.Products_DangerButton__jvezp {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Products_DangerButton__jvezp:hover,
.Products_DangerButton__jvezp:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Products_DangerButton__jvezp:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Products_WarningButton__Ge2Rm {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Products_WarningButton__Ge2Rm:hover,
.Products_WarningButton__Ge2Rm:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Products_WarningButton__Ge2Rm:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Products_PrimaryButton__32vok {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Products_PrimaryButton__32vok:hover,
.Products_PrimaryButton__32vok:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Products_PrimaryButton__32vok:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Products_SuccessButton__18tfR {
  background: #46d485;
  border-color: #46d485;
}
.Products_SuccessButton__18tfR:hover,
.Products_SuccessButton__18tfR:focus {
  background: #46d485;
  border-color: #46d485;
}
.Products_SuccessButton__18tfR:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Products_Products__9dt_J {
  height: 100%;
}
.Products_Products__9dt_J .Products_Block__35yne {
  height: calc((70vw - 5px * 11) / 12 * 4 - 58px - 48px);
}
@media all and (max-width: 1200px) {
  .Products_Products__9dt_J .Products_Block__35yne {
    height: calc((100vw - 5px * 7) / 8 * 4 - 58px - 48px);
  }
}
@media all and (max-width: 768px) {
  .Products_Products__9dt_J .Products_Block__35yne {
    height: calc((100vw - 5px * 3) - 58px - 48px);
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.ItemWrapper_DangerColor__3_52M {
  color: #ff8a4d;
}
.ItemWrapper_WarningColor__2epgM {
  color: #ff8a4d;
}
.ItemWrapper_PrimaryColor__1IaFb {
  color: #1c58e8;
}
.ItemWrapper_SuccessColor__Oy6l7 {
  color: #46d485;
}
.ItemWrapper_DangerButton__Hepph {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ItemWrapper_DangerButton__Hepph:hover,
.ItemWrapper_DangerButton__Hepph:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ItemWrapper_DangerButton__Hepph:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ItemWrapper_WarningButton__aAen_ {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ItemWrapper_WarningButton__aAen_:hover,
.ItemWrapper_WarningButton__aAen_:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ItemWrapper_WarningButton__aAen_:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ItemWrapper_PrimaryButton__1tbTL {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ItemWrapper_PrimaryButton__1tbTL:hover,
.ItemWrapper_PrimaryButton__1tbTL:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ItemWrapper_PrimaryButton__1tbTL:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.ItemWrapper_SuccessButton__qbBnP {
  background: #46d485;
  border-color: #46d485;
}
.ItemWrapper_SuccessButton__qbBnP:hover,
.ItemWrapper_SuccessButton__qbBnP:focus {
  background: #46d485;
  border-color: #46d485;
}
.ItemWrapper_SuccessButton__qbBnP:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.ItemWrapper_ItemWrapper__1_XKz {
  height: calc(((70vw - 5px * 11) / 12 * 4 - 58px - 48px) / 7);
  display: flex;
  align-items: center;
}
@media all and (max-width: 1200px) {
  .ItemWrapper_ItemWrapper__1_XKz {
    height: calc(((100vw - 5px * 7) / 8 * 4 - 58px - 48px) / 7);
  }
  .ItemWrapper_ItemWrapper__1_XKz .ant-tag {
    padding: 8px 0;
  }
}
@media all and (max-width: 768px) {
  .ItemWrapper_ItemWrapper__1_XKz {
    height: calc(((100vw - 5px * 3) - 58px - 48px) / 7);
  }
  .ItemWrapper_ItemWrapper__1_XKz .ant-tag {
    padding: 5px 0;
  }
}
.ItemWrapper_ItemWrapper__1_XKz > :first-child {
  flex: 1 1;
}
.ItemWrapper_ItemWrapper__1_XKz > :last-child {
  padding-bottom: 0;
}
.ItemWrapper_ItemWrapper__1_XKz .ItemWrapper_Tag__25I5Z {
  text-align: center;
  line-height: 1;
  cursor: pointer;
  width: calc(((70vw - 5px * 11) / 12 * 4 - 58px - 48px) / 10 * 6.5);
  font-size: calc(((70vw - 5px * 11) / 12 * 4 - 58px - 48px) / 14);
}
@media all and (max-width: 1200px) {
  .ItemWrapper_ItemWrapper__1_XKz .ItemWrapper_Tag__25I5Z {
    width: calc(((100vw - 5px * 7) / 8 * 4 - 58px - 48px) / 10 * 5.5);
    font-size: calc(((100vw - 5px * 7) / 8 * 4 - 58px - 48px) / 14);
  }
}
@media all and (max-width: 768px) {
  .ItemWrapper_ItemWrapper__1_XKz .ItemWrapper_Tag__25I5Z {
    width: calc(((100vw - 5px * 3) - 58px - 48px) / 10 * 8);
    font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 4);
  }
}
.ItemWrapper_ItemWrapper__1_XKz .ItemWrapper_Text__3EXEg {
  line-height: 1;
  cursor: pointer;
  font-size: calc(((70vw - 5px * 11) / 12 * 4 - 58px - 48px) / 9);
}
@media all and (max-width: 1200px) {
  .ItemWrapper_ItemWrapper__1_XKz .ItemWrapper_Text__3EXEg {
    font-size: calc(((100vw - 5px * 7) / 8 * 4 - 58px - 48px) / 9);
  }
}
@media all and (max-width: 768px) {
  .ItemWrapper_ItemWrapper__1_XKz .ItemWrapper_Text__3EXEg {
    font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 2);
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.StatusBar_DangerColor__2X44t {
  color: #ff8a4d;
}
.StatusBar_WarningColor__3TfRK {
  color: #ff8a4d;
}
.StatusBar_PrimaryColor__sIYbh {
  color: #1c58e8;
}
.StatusBar_SuccessColor__2Pc86 {
  color: #46d485;
}
.StatusBar_DangerButton__3_sdJ {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.StatusBar_DangerButton__3_sdJ:hover,
.StatusBar_DangerButton__3_sdJ:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.StatusBar_DangerButton__3_sdJ:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.StatusBar_WarningButton__3Zf79 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.StatusBar_WarningButton__3Zf79:hover,
.StatusBar_WarningButton__3Zf79:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.StatusBar_WarningButton__3Zf79:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.StatusBar_PrimaryButton__4PyCv {
  background: #1c58e8;
  border-color: #1c58e8;
}
.StatusBar_PrimaryButton__4PyCv:hover,
.StatusBar_PrimaryButton__4PyCv:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.StatusBar_PrimaryButton__4PyCv:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.StatusBar_SuccessButton__3RXN3 {
  background: #46d485;
  border-color: #46d485;
}
.StatusBar_SuccessButton__3RXN3:hover,
.StatusBar_SuccessButton__3RXN3:focus {
  background: #46d485;
  border-color: #46d485;
}
.StatusBar_SuccessButton__3RXN3:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.StatusBar_StatusBar__1V4r9 {
  height: 100%;
}
.StatusBar_StatusBar__1V4r9 .StatusBar_Body__11XTr {
  height: calc((70vw - 5px * 11) / 12 * 4 - 58px - 48px);
}
@media all and (max-width: 1200px) {
  .StatusBar_StatusBar__1V4r9 .StatusBar_Body__11XTr {
    height: calc((100vw - 5px * 7) / 8 * 4 - 58px - 48px);
  }
}
@media all and (max-width: 768px) {
  .StatusBar_StatusBar__1V4r9 .StatusBar_Body__11XTr {
    height: calc((100vw - 5px * 3) / 2 - 58px - 48px);
  }
}

.CreateUser_CreateUser__1Pfsr .CreateUser_Button__FunMp {
  display: flex;
  align-items: center;
  padding: 0;
}
.CreateUser_Modal__yuAZS > input + input,
.CreateUser_Modal__yuAZS input + div,
.CreateUser_Modal__yuAZS div + input {
  margin-top: 10px;
}
.CreateUser_Modal__yuAZS .ant-select-selection-item-remove {
  display: flex;
  align-items: center;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Users_DangerColor__3kQ4D {
  color: #ff8a4d;
}
.Users_WarningColor__2NEUe {
  color: #ff8a4d;
}
.Users_PrimaryColor__T3HN3 {
  color: #1c58e8;
}
.Users_SuccessColor__21djQ {
  color: #46d485;
}
.Users_DangerButton__aCrIb {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Users_DangerButton__aCrIb:hover,
.Users_DangerButton__aCrIb:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Users_DangerButton__aCrIb:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Users_WarningButton__1lczp {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Users_WarningButton__1lczp:hover,
.Users_WarningButton__1lczp:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Users_WarningButton__1lczp:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Users_PrimaryButton__15NLY {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Users_PrimaryButton__15NLY:hover,
.Users_PrimaryButton__15NLY:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Users_PrimaryButton__15NLY:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Users_SuccessButton__2Iiad {
  background: #46d485;
  border-color: #46d485;
}
.Users_SuccessButton__2Iiad:hover,
.Users_SuccessButton__2Iiad:focus {
  background: #46d485;
  border-color: #46d485;
}
.Users_SuccessButton__2Iiad:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Users_Users__2mir1 {
  height: 100%;
}
.Users_Users__2mir1 .Users_Active__7EIGi {
  cursor: pointer;
}
.Users_Users__2mir1 .Users_Button__23UQR {
  padding: 0;
  display: flex;
  align-items: center;
}
.Users_Users__2mir1 .Users_CountUsers__2bkFh {
  width: 100%;
  text-align: center;
  line-height: 1;
  font-size: calc((70vw - 5px * 11) / 12 * 2 - 58px - 48px);
}
@media all and (max-width: 1200px) {
  .Users_Users__2mir1 .Users_CountUsers__2bkFh {
    font-size: calc((100vw - 5px * 7) / 8 * 2 - 58px - 48px);
  }
}
@media all and (max-width: 768px) {
  .Users_Users__2mir1 .Users_CountUsers__2bkFh {
    font-size: calc((100vw - 5px * 3) / 2 - 58px - 48px);
  }
}
.Users_Disabled__2YaUF {
  pointer-events: none;
  opacity: 0.4;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Auth_DangerColor__1OxX5 {
  color: #ff8a4d;
}
.Auth_WarningColor__zVISD {
  color: #ff8a4d;
}
.Auth_PrimaryColor__rWLON {
  color: #1c58e8;
}
.Auth_SuccessColor__tlQ2W {
  color: #46d485;
}
.Auth_DangerButton__2FG8s {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Auth_DangerButton__2FG8s:hover,
.Auth_DangerButton__2FG8s:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Auth_DangerButton__2FG8s:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Auth_WarningButton__deajm {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Auth_WarningButton__deajm:hover,
.Auth_WarningButton__deajm:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Auth_WarningButton__deajm:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Auth_PrimaryButton__12o3Q {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Auth_PrimaryButton__12o3Q:hover,
.Auth_PrimaryButton__12o3Q:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Auth_PrimaryButton__12o3Q:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Auth_SuccessButton__1AgLi {
  background: #46d485;
  border-color: #46d485;
}
.Auth_SuccessButton__1AgLi:hover,
.Auth_SuccessButton__1AgLi:focus {
  background: #46d485;
  border-color: #46d485;
}
.Auth_SuccessButton__1AgLi:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Auth_Authentication__2ybvx {
  height: 100%;
  min-height: 100vh;
  display: flex;
  background: linear-gradient(90deg, #1c58e8 50%, #fff0 0);
}
.Auth_Authentication__2ybvx .Auth_Body__oQ7CZ {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5vh;
}
.Auth_Authentication__2ybvx .Auth_Body__oQ7CZ > svg {
  z-index: 10;
  height: 30vh;
  width: 50vw;
  padding-bottom: 10vh;
}
.Auth_Authentication__2ybvx .Auth_Routers__21uqq {
  min-width: 320px;
  max-width: 600px;
  margin-bottom: 50px;
}
.Auth_Authentication__2ybvx .Auth_Routers__21uqq > div {
  min-width: 320px;
}
.Auth_Show__3u9w5 {
  display: unset;
}
.Auth_Hiden__1BnKV {
  display: none;
}
.Auth_Auth__2YUyk {
  width: 400px;
}
.Auth_Auth__2YUyk input + input {
  margin-top: 10px;
}
.Auth_Auth__2YUyk .Auth_ControlPanel__1eqGm {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.Auth_Auth__2YUyk .Auth_ControlPanel__1eqGm .Auth_ResetPassword__2fKS7 {
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.ReturnCard_DangerColor__s8uco {
  color: #ff8a4d;
}
.ReturnCard_WarningColor__2I-h5 {
  color: #ff8a4d;
}
.ReturnCard_PrimaryColor__S4uvR {
  color: #1c58e8;
}
.ReturnCard_SuccessColor__3f_Hj {
  color: #46d485;
}
.ReturnCard_DangerButton__1Fbj0 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ReturnCard_DangerButton__1Fbj0:hover,
.ReturnCard_DangerButton__1Fbj0:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ReturnCard_DangerButton__1Fbj0:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ReturnCard_WarningButton__Wy53F {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ReturnCard_WarningButton__Wy53F:hover,
.ReturnCard_WarningButton__Wy53F:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ReturnCard_WarningButton__Wy53F:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ReturnCard_PrimaryButton__3Ys0t {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ReturnCard_PrimaryButton__3Ys0t:hover,
.ReturnCard_PrimaryButton__3Ys0t:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ReturnCard_PrimaryButton__3Ys0t:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.ReturnCard_SuccessButton__3YQMS {
  background: #46d485;
  border-color: #46d485;
}
.ReturnCard_SuccessButton__3YQMS:hover,
.ReturnCard_SuccessButton__3YQMS:focus {
  background: #46d485;
  border-color: #46d485;
}
.ReturnCard_SuccessButton__3YQMS:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.ReturnCard_ReturnCard__22oL5 .ant-card-body {
  padding: 10px;
}
.ReturnCard_ReturnCard__22oL5 .ReturnCard_Body__2cV7F {
  margin-top: 15px;
  padding: 9px;
}
.ReturnCard_ReturnCard__22oL5 .ReturnCard_Header__1HHZ5 {
  display: flex;
  align-items: center;
}
.ReturnCard_ReturnCard__22oL5 .ReturnCard_Header__1HHZ5 .ReturnCard_Icon__13zMK {
  font-size: 20px;
  color: #1c58e8;
  cursor: pointer;
}
.ReturnCard_ReturnCard__22oL5 .ReturnCard_Header__1HHZ5 .ReturnCard_Title__MZKlM {
  margin-left: 10px;
  margin-right: 5px;
  font-size: 20px;
  color: #c4c4c4;
}
.ReturnCard_ReturnCard__22oL5 .ReturnCard_SubTitle__pI1L9 {
  font-size: 17px;
  margin: 0 9px;
  margin-top: 10px;
}

.Os_Center__3p5qm {
  width: 100%;
  display: flex;
  justify-content: center;
}

.QrCode_QrCode__O6ghG {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}
.QrCode_Os__qiutI {
  margin-top: 17px;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.CheckTwoFa_DangerColor__24Zzm {
  color: #ff8a4d;
}
.CheckTwoFa_WarningColor__2mQR0 {
  color: #ff8a4d;
}
.CheckTwoFa_PrimaryColor__oc324 {
  color: #1c58e8;
}
.CheckTwoFa_SuccessColor__3vCAG {
  color: #46d485;
}
.CheckTwoFa_DangerButton__2SfjJ {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CheckTwoFa_DangerButton__2SfjJ:hover,
.CheckTwoFa_DangerButton__2SfjJ:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CheckTwoFa_DangerButton__2SfjJ:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.CheckTwoFa_WarningButton__2O8IL {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CheckTwoFa_WarningButton__2O8IL:hover,
.CheckTwoFa_WarningButton__2O8IL:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.CheckTwoFa_WarningButton__2O8IL:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.CheckTwoFa_PrimaryButton__3Mkea {
  background: #1c58e8;
  border-color: #1c58e8;
}
.CheckTwoFa_PrimaryButton__3Mkea:hover,
.CheckTwoFa_PrimaryButton__3Mkea:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.CheckTwoFa_PrimaryButton__3Mkea:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.CheckTwoFa_SuccessButton__2cot8 {
  background: #46d485;
  border-color: #46d485;
}
.CheckTwoFa_SuccessButton__2cot8:hover,
.CheckTwoFa_SuccessButton__2cot8:focus {
  background: #46d485;
  border-color: #46d485;
}
.CheckTwoFa_SuccessButton__2cot8:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.CheckTwoFa_Authentication__1jHq5 {
  height: 100%;
  min-height: 100vh;
  display: flex;
  background: linear-gradient(90deg, #1c58e8 50%, #fff0 0);
}
.CheckTwoFa_Authentication__1jHq5 .CheckTwoFa_Body__1YDQZ {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5vh;
}
.CheckTwoFa_Authentication__1jHq5 .CheckTwoFa_Body__1YDQZ > svg {
  z-index: 10;
  height: 30vh;
  width: 50vw;
  padding-bottom: 10vh;
}
.CheckTwoFa_Authentication__1jHq5 .CheckTwoFa_Routers__3vh0Y {
  min-width: 320px;
  max-width: 600px;
  margin-bottom: 50px;
}
.CheckTwoFa_Authentication__1jHq5 .CheckTwoFa_Routers__3vh0Y > div {
  min-width: 320px;
}
.CheckTwoFa_Show__3bmos {
  display: unset;
}
.CheckTwoFa_Hiden__q5TNc {
  display: none;
}
.CheckTwoFa_CheckTwoFa__2plCv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 500px) {
  .CheckTwoFa_CheckTwoFa__2plCv {
    width: auto;
  }
}
.CheckTwoFa_CheckTwoFa__2plCv .CheckTwoFa_ControlPanel__2bSUF {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.CheckTwoFa_CheckTwoFa__2plCv .CheckTwoFa_ControlPanel__2bSUF .CheckTwoFa_ResetPassword__1FdA5 {
  padding-right: 0;
  padding-left: 0;
}
.CheckTwoFa_CheckTwoFa__2plCv .CheckTwoFa_Input__1ZkUa {
  color: #9e9c9c;
  font-size: 50px;
  border: 0;
  text-align: center;
  font-family: 'Inconsolata', monospace;
}
.CheckTwoFa_CheckTwoFa__2plCv span + input {
  margin-top: 10px;
}

.Sms_CountdownTimer__1oC8W {
  padding-left: 0;
}
.Sms_HintPhone__1lLmr {
  width: 100%;
  display: flex;
  justify-content: center;
}


.ChangePhone_ChangePhone__3lYM1 {
  display: flex;
  justify-content: center;
}
.ChangePhone_Description__2imf- {
  font-size: 12px;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.ChoiceTwoFa_DangerColor__2wIwK {
  color: #ff8a4d;
}
.ChoiceTwoFa_WarningColor__1YZ3P {
  color: #ff8a4d;
}
.ChoiceTwoFa_PrimaryColor__720NT {
  color: #1c58e8;
}
.ChoiceTwoFa_SuccessColor__gckkw {
  color: #46d485;
}
.ChoiceTwoFa_DangerButton__2hntd {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ChoiceTwoFa_DangerButton__2hntd:hover,
.ChoiceTwoFa_DangerButton__2hntd:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ChoiceTwoFa_DangerButton__2hntd:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ChoiceTwoFa_WarningButton__1YbrC {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ChoiceTwoFa_WarningButton__1YbrC:hover,
.ChoiceTwoFa_WarningButton__1YbrC:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ChoiceTwoFa_WarningButton__1YbrC:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ChoiceTwoFa_PrimaryButton__2RdFF {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ChoiceTwoFa_PrimaryButton__2RdFF:hover,
.ChoiceTwoFa_PrimaryButton__2RdFF:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ChoiceTwoFa_PrimaryButton__2RdFF:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.ChoiceTwoFa_SuccessButton__2rB2_ {
  background: #46d485;
  border-color: #46d485;
}
.ChoiceTwoFa_SuccessButton__2rB2_:hover,
.ChoiceTwoFa_SuccessButton__2rB2_:focus {
  background: #46d485;
  border-color: #46d485;
}
.ChoiceTwoFa_SuccessButton__2rB2_:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.ChoiceTwoFa_Authentication__YIhZw {
  height: 100%;
  min-height: 100vh;
  display: flex;
  background: linear-gradient(90deg, #1c58e8 50%, #fff0 0);
}
.ChoiceTwoFa_Authentication__YIhZw .ChoiceTwoFa_Body__1Mwqu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5vh;
}
.ChoiceTwoFa_Authentication__YIhZw .ChoiceTwoFa_Body__1Mwqu > svg {
  z-index: 10;
  height: 30vh;
  width: 50vw;
  padding-bottom: 10vh;
}
.ChoiceTwoFa_Authentication__YIhZw .ChoiceTwoFa_Routers__2jaK6 {
  min-width: 320px;
  max-width: 600px;
  margin-bottom: 50px;
}
.ChoiceTwoFa_Authentication__YIhZw .ChoiceTwoFa_Routers__2jaK6 > div {
  min-width: 320px;
}
.ChoiceTwoFa_Show__3OMit {
  display: unset;
}
.ChoiceTwoFa_Hiden__JN6tc {
  display: none;
}
.ChoiceTwoFa_ChoiceTwoFa__oDXYo .ChoiceTwoFa_SelectChoiceTwoFa__172Vp {
  width: 100%;
}
.ChoiceTwoFa_ChoiceTwoFa__oDXYo .ChoiceTwoFa_ControlPanel__1r2pf {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.ChoiceTwoFa_ChoiceTwoFa__oDXYo .ChoiceTwoFa_ControlPanel__1r2pf .ChoiceTwoFa_ResetPassword__itnmO {
  padding-right: 0;
  padding-left: 0;
}
.ChoiceTwoFa_ChoiceTwoFa__oDXYo .ChoiceTwoFa_Description__1O5ss {
  color: #000;
}
.ChoiceTwoFa_ChoiceTwoFa__oDXYo .ChoiceTwoFa_Description__1O5ss span + div {
  margin-top: 10px;
}

.MobilePhoneField_MobilePhoneField__qesmg {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.ResetPassword_DangerColor__3s6rO {
  color: #ff8a4d;
}
.ResetPassword_WarningColor__3UgAw {
  color: #ff8a4d;
}
.ResetPassword_PrimaryColor__3Pidt {
  color: #1c58e8;
}
.ResetPassword_SuccessColor__3Arj_ {
  color: #46d485;
}
.ResetPassword_DangerButton__3fM87 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ResetPassword_DangerButton__3fM87:hover,
.ResetPassword_DangerButton__3fM87:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ResetPassword_DangerButton__3fM87:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ResetPassword_WarningButton__1_YpS {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ResetPassword_WarningButton__1_YpS:hover,
.ResetPassword_WarningButton__1_YpS:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.ResetPassword_WarningButton__1_YpS:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.ResetPassword_PrimaryButton__1uNhH {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ResetPassword_PrimaryButton__1uNhH:hover,
.ResetPassword_PrimaryButton__1uNhH:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.ResetPassword_PrimaryButton__1uNhH:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.ResetPassword_SuccessButton__1OTwR {
  background: #46d485;
  border-color: #46d485;
}
.ResetPassword_SuccessButton__1OTwR:hover,
.ResetPassword_SuccessButton__1OTwR:focus {
  background: #46d485;
  border-color: #46d485;
}
.ResetPassword_SuccessButton__1OTwR:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.ResetPassword_Authentication__1dBq6 {
  height: 100%;
  min-height: 100vh;
  display: flex;
  background: linear-gradient(90deg, #1c58e8 50%, #fff0 0);
}
.ResetPassword_Authentication__1dBq6 .ResetPassword_Body__2PCkh {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5vh;
}
.ResetPassword_Authentication__1dBq6 .ResetPassword_Body__2PCkh > svg {
  z-index: 10;
  height: 30vh;
  width: 50vw;
  padding-bottom: 10vh;
}
.ResetPassword_Authentication__1dBq6 .ResetPassword_Routers__230Ln {
  min-width: 320px;
  max-width: 600px;
  margin-bottom: 50px;
}
.ResetPassword_Authentication__1dBq6 .ResetPassword_Routers__230Ln > div {
  min-width: 320px;
}
.ResetPassword_Show__2PbQd {
  display: unset;
}
.ResetPassword_Hiden__cqquF {
  display: none;
}
.ResetPassword_ResetPassword__1Gd2R {
  width: 320px;
}
.ResetPassword_ResetPassword__1Gd2R input + input {
  margin-top: 10px;
}
.ResetPassword_ResetPassword__1Gd2R .ResetPassword_ControlPanel__U94y3 {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
.ResetPassword_ResetPassword__1Gd2R .ResetPassword_ControlPanel__U94y3 .ResetPassword_ResetPassword__1Gd2R {
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.RecoveryPassword_DangerColor__1GT2Y {
  color: #ff8a4d;
}
.RecoveryPassword_WarningColor__3Xp4K {
  color: #ff8a4d;
}
.RecoveryPassword_PrimaryColor__1hyPt {
  color: #1c58e8;
}
.RecoveryPassword_SuccessColor__2ybj3 {
  color: #46d485;
}
.RecoveryPassword_DangerButton__xcYNK {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.RecoveryPassword_DangerButton__xcYNK:hover,
.RecoveryPassword_DangerButton__xcYNK:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.RecoveryPassword_DangerButton__xcYNK:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.RecoveryPassword_WarningButton__2xpZJ {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.RecoveryPassword_WarningButton__2xpZJ:hover,
.RecoveryPassword_WarningButton__2xpZJ:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.RecoveryPassword_WarningButton__2xpZJ:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.RecoveryPassword_PrimaryButton__u83OT {
  background: #1c58e8;
  border-color: #1c58e8;
}
.RecoveryPassword_PrimaryButton__u83OT:hover,
.RecoveryPassword_PrimaryButton__u83OT:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.RecoveryPassword_PrimaryButton__u83OT:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.RecoveryPassword_SuccessButton__tRYsk {
  background: #46d485;
  border-color: #46d485;
}
.RecoveryPassword_SuccessButton__tRYsk:hover,
.RecoveryPassword_SuccessButton__tRYsk:focus {
  background: #46d485;
  border-color: #46d485;
}
.RecoveryPassword_SuccessButton__tRYsk:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.RecoveryPassword_Authentication__uJsPp {
  height: 100%;
  min-height: 100vh;
  display: flex;
  background: linear-gradient(90deg, #1c58e8 50%, #fff0 0);
}
.RecoveryPassword_Authentication__uJsPp .RecoveryPassword_Body__3qwy- {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5vh;
}
.RecoveryPassword_Authentication__uJsPp .RecoveryPassword_Body__3qwy- > svg {
  z-index: 10;
  height: 30vh;
  width: 50vw;
  padding-bottom: 10vh;
}
.RecoveryPassword_Authentication__uJsPp .RecoveryPassword_Routers__zDH1Q {
  min-width: 320px;
  max-width: 600px;
  margin-bottom: 50px;
}
.RecoveryPassword_Authentication__uJsPp .RecoveryPassword_Routers__zDH1Q > div {
  min-width: 320px;
}
.RecoveryPassword_Show__13z1k {
  display: unset;
}
.RecoveryPassword_Hiden__3dK40 {
  display: none;
}
.RecoveryPassword_RecoveryPassword__1-K2f {
  width: 320px;
}
.RecoveryPassword_RecoveryPassword__1-K2f input + input {
  margin-top: 10px;
}
.RecoveryPassword_RecoveryPassword__1-K2f .RecoveryPassword_ControlPanel__2tXE6 {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
.RecoveryPassword_RecoveryPassword__1-K2f .RecoveryPassword_ControlPanel__2tXE6 .RecoveryPassword_ResetPassword__rm9Rz {
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Authentication_DangerColor__11Yxj {
  color: #ff8a4d;
}
.Authentication_WarningColor__3zqOA {
  color: #ff8a4d;
}
.Authentication_PrimaryColor__2dSWA {
  color: #1c58e8;
}
.Authentication_SuccessColor__30Zyh {
  color: #46d485;
}
.Authentication_DangerButton__2eeaM {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Authentication_DangerButton__2eeaM:hover,
.Authentication_DangerButton__2eeaM:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Authentication_DangerButton__2eeaM:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Authentication_WarningButton__3bNae {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Authentication_WarningButton__3bNae:hover,
.Authentication_WarningButton__3bNae:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Authentication_WarningButton__3bNae:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Authentication_PrimaryButton__PKbJt {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Authentication_PrimaryButton__PKbJt:hover,
.Authentication_PrimaryButton__PKbJt:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Authentication_PrimaryButton__PKbJt:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Authentication_SuccessButton__2b13k {
  background: #46d485;
  border-color: #46d485;
}
.Authentication_SuccessButton__2b13k:hover,
.Authentication_SuccessButton__2b13k:focus {
  background: #46d485;
  border-color: #46d485;
}
.Authentication_SuccessButton__2b13k:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Authentication_Authentication__1IV0P {
  height: 100%;
  min-height: 100vh;
  display: flex;
  background: linear-gradient(90deg, #1c58e8 50%, #fff0 0);
}
.Authentication_Authentication__1IV0P .Authentication_Body__1sMlB {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5vh;
}
.Authentication_Authentication__1IV0P .Authentication_Body__1sMlB > svg {
  z-index: 10;
  height: 30vh;
  width: 50vw;
  padding-bottom: 10vh;
}
.Authentication_Authentication__1IV0P .Authentication_Routers__IL7vu {
  min-width: 320px;
  max-width: 600px;
  margin-bottom: 50px;
}
.Authentication_Authentication__1IV0P .Authentication_Routers__IL7vu > div {
  min-width: 320px;
}
.Authentication_Show__1ybAX {
  display: unset;
}
.Authentication_Hiden__3OykP {
  display: none;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Header_DangerColor__2Q8Av {
  color: #ff8a4d;
}
.Header_WarningColor__2a-RB {
  color: #ff8a4d;
}
.Header_PrimaryColor__1qf2b {
  color: #1c58e8;
}
.Header_SuccessColor__3IpgZ {
  color: #46d485;
}
.Header_DangerButton__1w1Dt {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_DangerButton__1w1Dt:hover,
.Header_DangerButton__1w1Dt:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_DangerButton__1w1Dt:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Header_WarningButton__1T-_y {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_WarningButton__1T-_y:hover,
.Header_WarningButton__1T-_y:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_WarningButton__1T-_y:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Header_PrimaryButton__xUh1B {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Header_PrimaryButton__xUh1B:hover,
.Header_PrimaryButton__xUh1B:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Header_PrimaryButton__xUh1B:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Header_SuccessButton__q29_r {
  background: #46d485;
  border-color: #46d485;
}
.Header_SuccessButton__q29_r:hover,
.Header_SuccessButton__q29_r:focus {
  background: #46d485;
  border-color: #46d485;
}
.Header_SuccessButton__q29_r:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Header_Header__2XlDi {
  border: 1px solid #ebedf0;
  background: white;
}



.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.WrapperField_DangerColor__mEIso {
  color: #ff8a4d;
}
.WrapperField_WarningColor__37s3W {
  color: #ff8a4d;
}
.WrapperField_PrimaryColor__1JbFJ {
  color: #1c58e8;
}
.WrapperField_SuccessColor__3NJp4 {
  color: #46d485;
}
.WrapperField_DangerButton__7HITh {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.WrapperField_DangerButton__7HITh:hover,
.WrapperField_DangerButton__7HITh:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.WrapperField_DangerButton__7HITh:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.WrapperField_WarningButton__2jRTs {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.WrapperField_WarningButton__2jRTs:hover,
.WrapperField_WarningButton__2jRTs:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.WrapperField_WarningButton__2jRTs:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.WrapperField_PrimaryButton__6L0nc {
  background: #1c58e8;
  border-color: #1c58e8;
}
.WrapperField_PrimaryButton__6L0nc:hover,
.WrapperField_PrimaryButton__6L0nc:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.WrapperField_PrimaryButton__6L0nc:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.WrapperField_SuccessButton__1jBqS {
  background: #46d485;
  border-color: #46d485;
}
.WrapperField_SuccessButton__1jBqS:hover,
.WrapperField_SuccessButton__1jBqS:focus {
  background: #46d485;
  border-color: #46d485;
}
.WrapperField_SuccessButton__1jBqS:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.WrapperField_WrapperField__3KB2M {
  display: flex;
  align-items: center;
  min-height: 32px;
}
@media all and (max-width: 768px) {
  .WrapperField_WrapperField__3KB2M {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.WrapperField_WrapperField__3KB2M .WrapperField_Title__6iqNL {
  line-height: 1;
  margin-right: 3px;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  margin-right: 10px;
}
.WrapperField_WrapperField__3KB2M .WrapperField_Data__2cirO {
  display: flex;
  width: 100%;
}
.WrapperField_WrapperField__3KB2M .WrapperField_Data__2cirO .WrapperField_Text__1zAdK {
  display: flex;
  align-items: center;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.45);
}
@media all and (max-width: 768px) {
  .WrapperField_WrapperField__3KB2M .WrapperField_Data__2cirO .WrapperField_Text__1zAdK {
    flex-grow: 1;
    flex-wrap: wrap;
  }
}
.WrapperField_WrapperField__3KB2M .WrapperField_Data__2cirO .WrapperField_Button__FOmFE {
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: 5px;
}
.WrapperField_WrapperField__3KB2M .WrapperField_Data__2cirO .WrapperField_Button__FOmFE .WrapperField_Icon__10BkN:hover svg path {
  fill: #1c58e8;
}
.WrapperField_WrapperField__3KB2M .WrapperField_Data__2cirO .WrapperField_Button__FOmFE .WrapperField_Icon__10BkN:clock svg path {
  fill: #5e70cc;
}
.WrapperField_WrapperField__3KB2M .WrapperField_Data__2cirO .WrapperField_Button__FOmFE .WrapperField_Icon__10BkN svg path {
  fill: #000;
}


.LogoPartner_LogoPartner__3mhbJ .LogoPartner_Image__1z0nI {
  width: unset;
  height: 25px;
}

.SitePartner_SitePartner__YhgOw .SitePartner_Button__16jZv {
  padding: 0;
}

.Demo_Demo__RUQV- .Demo_On__1XJVq {
  color: green;
}
.Demo_Demo__RUQV- .Demo_Off__1Rbtd {
  color: red;
}

.EditModal_EditModal__zxmvC input + input {
  margin-top: 10px;
}


.EditModal_EditModal__1sJGD input + input {
  margin-top: 10px;
}


.EditModal_EditModal__2pvSV input + input {
  margin-top: 10px;
}

.Password_Password__1nGdt .Password_Button__9Bwjm {
  display: flex;
  align-items: center;
}
.Password_Password__1nGdt .Password_Button__9Bwjm .Password_Icon__1dz9Q {
  display: flex;
  align-items: center;
}

.EditModal_EditModal__X8r7i input + input {
  margin-top: 10px;
}


.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.PersonalInformation_DangerColor__LrQEw {
  color: #ff8a4d;
}
.PersonalInformation_WarningColor__1WmOV {
  color: #ff8a4d;
}
.PersonalInformation_PrimaryColor__1gRCw {
  color: #1c58e8;
}
.PersonalInformation_SuccessColor__2oB_r {
  color: #46d485;
}
.PersonalInformation_DangerButton__ooH7Q {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.PersonalInformation_DangerButton__ooH7Q:hover,
.PersonalInformation_DangerButton__ooH7Q:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.PersonalInformation_DangerButton__ooH7Q:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.PersonalInformation_WarningButton__akox5 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.PersonalInformation_WarningButton__akox5:hover,
.PersonalInformation_WarningButton__akox5:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.PersonalInformation_WarningButton__akox5:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.PersonalInformation_PrimaryButton__1AntZ {
  background: #1c58e8;
  border-color: #1c58e8;
}
.PersonalInformation_PrimaryButton__1AntZ:hover,
.PersonalInformation_PrimaryButton__1AntZ:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.PersonalInformation_PrimaryButton__1AntZ:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.PersonalInformation_SuccessButton__1LgsH {
  background: #46d485;
  border-color: #46d485;
}
.PersonalInformation_SuccessButton__1LgsH:hover,
.PersonalInformation_SuccessButton__1LgsH:focus {
  background: #46d485;
  border-color: #46d485;
}
.PersonalInformation_SuccessButton__1LgsH:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.PersonalInformation_PersonalInformation__T3aw1 {
  display: flex;
}
.PersonalInformation_PersonalInformation__T3aw1 .PersonalInformation_BlockOne__2UUwc {
  display: grid;
  grid-gap: 15px;
  min-width: 240px;
  grid-template-columns: 1fr;
  grid-gap: 5px;
}
.PersonalInformation_PersonalInformation__T3aw1 .PersonalInformation_BlockTwo__1oNuU {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media all and (max-width: 768px) {
  .PersonalInformation_PersonalInformation__T3aw1 {
    flex-direction: column;
  }
  .PersonalInformation_PersonalInformation__T3aw1 .PersonalInformation_BlockTwo__1oNuU {
    margin-top: 10px;
    justify-content: flex-start;
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Profile_DangerColor__bwEdZ {
  color: #ff8a4d;
}
.Profile_WarningColor__2DGtW {
  color: #ff8a4d;
}
.Profile_PrimaryColor__3yLwx {
  color: #1c58e8;
}
.Profile_SuccessColor__-08QL {
  color: #46d485;
}
.Profile_DangerButton__2uM9g {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Profile_DangerButton__2uM9g:hover,
.Profile_DangerButton__2uM9g:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Profile_DangerButton__2uM9g:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Profile_WarningButton__2Z2L9 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Profile_WarningButton__2Z2L9:hover,
.Profile_WarningButton__2Z2L9:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Profile_WarningButton__2Z2L9:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Profile_PrimaryButton__2yo2X {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Profile_PrimaryButton__2yo2X:hover,
.Profile_PrimaryButton__2yo2X:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Profile_PrimaryButton__2yo2X:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Profile_SuccessButton__2dn4V {
  background: #46d485;
  border-color: #46d485;
}
.Profile_SuccessButton__2dn4V:hover,
.Profile_SuccessButton__2dn4V:focus {
  background: #46d485;
  border-color: #46d485;
}
.Profile_SuccessButton__2dn4V:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Profile_Profile__t6xOD {
  border: 1px solid #ebedf0;
  background: white;
}
.Profile_Profile__t6xOD .ant-page-header-content {
  padding-top: 0;
}
.Profile_Cards__1Tjdd {
  margin-top: 15px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}
@media all and (max-width: 768px) {
  .Profile_Cards__1Tjdd {
    grid-template-columns: 1fr;
  }
}

.Header_Header__1Nk6k {
  border: 1px solid #ebedf0;
  background: white;
}

.TwoFaType_TwoFaType__2_vNf {
  display: flex;
}

.Table_Table__EHvEb {
  margin-top: 15px;
}
.Table_Table__EHvEb .Table_Switch__2fv24 {
  pointer-events: none;
  cursor: not-allowed;
}
.Table_Table__EHvEb .ant-table-row {
  cursor: pointer;
}
.Table_Filter__175Ss {
  padding: 8px;
  max-width: 300px;
}
.Table_Filter__175Ss > span + span {
  margin-top: 5px;
}


.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Header_DangerColor__1hYHE {
  color: #ff8a4d;
}
.Header_WarningColor__O72XD {
  color: #ff8a4d;
}
.Header_PrimaryColor__2Ggc_ {
  color: #1c58e8;
}
.Header_SuccessColor__27MAy {
  color: #46d485;
}
.Header_DangerButton__27fdj {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_DangerButton__27fdj:hover,
.Header_DangerButton__27fdj:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_DangerButton__27fdj:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Header_WarningButton__34pDo {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_WarningButton__34pDo:hover,
.Header_WarningButton__34pDo:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_WarningButton__34pDo:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Header_PrimaryButton__2JHqD {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Header_PrimaryButton__2JHqD:hover,
.Header_PrimaryButton__2JHqD:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Header_PrimaryButton__2JHqD:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Header_SuccessButton__1TWTG {
  background: #46d485;
  border-color: #46d485;
}
.Header_SuccessButton__1TWTG:hover,
.Header_SuccessButton__1TWTG:focus {
  background: #46d485;
  border-color: #46d485;
}
.Header_SuccessButton__1TWTG:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Header_Header__tbe1t {
  border: 1px solid #ebedf0;
  background: white;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Body_DangerColor__4eBIY {
  color: #ff8a4d;
}
.Body_WarningColor__39AnO {
  color: #ff8a4d;
}
.Body_PrimaryColor__1ApyC {
  color: #1c58e8;
}
.Body_SuccessColor__12Ynd {
  color: #46d485;
}
.Body_DangerButton__1LDqe {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Body_DangerButton__1LDqe:hover,
.Body_DangerButton__1LDqe:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Body_DangerButton__1LDqe:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Body_WarningButton__3Lqub {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Body_WarningButton__3Lqub:hover,
.Body_WarningButton__3Lqub:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Body_WarningButton__3Lqub:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Body_PrimaryButton__3oznj {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Body_PrimaryButton__3oznj:hover,
.Body_PrimaryButton__3oznj:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Body_PrimaryButton__3oznj:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Body_SuccessButton___NPtO {
  background: #46d485;
  border-color: #46d485;
}
.Body_SuccessButton___NPtO:hover,
.Body_SuccessButton___NPtO:focus {
  background: #46d485;
  border-color: #46d485;
}
.Body_SuccessButton___NPtO:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Body_Body__3Zzhv {
  margin-top: 15px;
  display: flex;
}
.Body_Body__3Zzhv > div + div {
  margin-left: 15px;
}
@media all and (max-width: 768px) {
  .Body_Body__3Zzhv {
    flex-direction: column;
  }
  .Body_Body__3Zzhv > div + div {
    margin-left: 0;
    margin-top: 15px;
  }
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.AttemptsFailed_DangerColor__2YOpp {
  color: #ff8a4d;
}
.AttemptsFailed_WarningColor__18KwZ {
  color: #ff8a4d;
}
.AttemptsFailed_PrimaryColor__3U7Qd {
  color: #1c58e8;
}
.AttemptsFailed_SuccessColor__172L0 {
  color: #46d485;
}
.AttemptsFailed_DangerButton__O1jVM {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AttemptsFailed_DangerButton__O1jVM:hover,
.AttemptsFailed_DangerButton__O1jVM:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AttemptsFailed_DangerButton__O1jVM:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.AttemptsFailed_WarningButton__2UB2d {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AttemptsFailed_WarningButton__2UB2d:hover,
.AttemptsFailed_WarningButton__2UB2d:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AttemptsFailed_WarningButton__2UB2d:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.AttemptsFailed_PrimaryButton__gbDe8 {
  background: #1c58e8;
  border-color: #1c58e8;
}
.AttemptsFailed_PrimaryButton__gbDe8:hover,
.AttemptsFailed_PrimaryButton__gbDe8:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.AttemptsFailed_PrimaryButton__gbDe8:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.AttemptsFailed_SuccessButton__2pl1H {
  background: #46d485;
  border-color: #46d485;
}
.AttemptsFailed_SuccessButton__2pl1H:hover,
.AttemptsFailed_SuccessButton__2pl1H:focus {
  background: #46d485;
  border-color: #46d485;
}
.AttemptsFailed_SuccessButton__2pl1H:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.AttemptsFailed_Yellow__3mIEq {
  color: #fbdb13;
}
.AttemptsFailed_Red__1jDp4 {
  color: #e26a6a;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.AttemptsTwoFa_DangerColor__3pQl8 {
  color: #ff8a4d;
}
.AttemptsTwoFa_WarningColor__PRaOk {
  color: #ff8a4d;
}
.AttemptsTwoFa_PrimaryColor__ihKAj {
  color: #1c58e8;
}
.AttemptsTwoFa_SuccessColor__rz9MF {
  color: #46d485;
}
.AttemptsTwoFa_DangerButton__1fOv5 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AttemptsTwoFa_DangerButton__1fOv5:hover,
.AttemptsTwoFa_DangerButton__1fOv5:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AttemptsTwoFa_DangerButton__1fOv5:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.AttemptsTwoFa_WarningButton__15OjM {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AttemptsTwoFa_WarningButton__15OjM:hover,
.AttemptsTwoFa_WarningButton__15OjM:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.AttemptsTwoFa_WarningButton__15OjM:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.AttemptsTwoFa_PrimaryButton__s1Jva {
  background: #1c58e8;
  border-color: #1c58e8;
}
.AttemptsTwoFa_PrimaryButton__s1Jva:hover,
.AttemptsTwoFa_PrimaryButton__s1Jva:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.AttemptsTwoFa_PrimaryButton__s1Jva:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.AttemptsTwoFa_SuccessButton__3nbZD {
  background: #46d485;
  border-color: #46d485;
}
.AttemptsTwoFa_SuccessButton__3nbZD:hover,
.AttemptsTwoFa_SuccessButton__3nbZD:focus {
  background: #46d485;
  border-color: #46d485;
}
.AttemptsTwoFa_SuccessButton__3nbZD:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.AttemptsTwoFa_Yellow__15cVX {
  color: #fbdb13;
}
.AttemptsTwoFa_Red__2F4Sq {
  color: #e26a6a;
}

.EditModal_EditModal__2_oL5 input + input {
  margin-top: 10px;
}
.EditModal_EditModal__2_oL5 .ant-select-selection-item-remove {
  display: flex;
  align-items: center;
}
.EditModal_EditModal__2_oL5 .EditModal_TextBold__CDLnW {
  text-transform: capitalize;
}


.ChangeStatusTwoFA_ChangeStatusTwoFA__3a8N2 .ChangeStatusTwoFA_Button__2XlNt {
  padding: 0;
}

.EditModal_EditModal__2wsdb input + input {
  margin-top: 10px;
}
.EditModal_EditModal__2wsdb .ant-select-selection-item-remove {
  display: flex;
  align-items: center;
}
.EditModal_EditModal__2wsdb .EditModal_TextBold__2zaWa {
  text-transform: capitalize;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Email_DangerColor__VuJiY {
  color: #ff8a4d;
}
.Email_WarningColor__1DNxa {
  color: #ff8a4d;
}
.Email_PrimaryColor__3NEKU {
  color: #1c58e8;
}
.Email_SuccessColor__3fxw3 {
  color: #46d485;
}
.Email_DangerButton__3QaKF {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Email_DangerButton__3QaKF:hover,
.Email_DangerButton__3QaKF:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Email_DangerButton__3QaKF:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Email_WarningButton__31dhI {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Email_WarningButton__31dhI:hover,
.Email_WarningButton__31dhI:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Email_WarningButton__31dhI:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Email_PrimaryButton__3Tf66 {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Email_PrimaryButton__3Tf66:hover,
.Email_PrimaryButton__3Tf66:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Email_PrimaryButton__3Tf66:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Email_SuccessButton__31EIn {
  background: #46d485;
  border-color: #46d485;
}
.Email_SuccessButton__31EIn:hover,
.Email_SuccessButton__31EIn:focus {
  background: #46d485;
  border-color: #46d485;
}
.Email_SuccessButton__31EIn:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Email_Body__2LY1f {
  margin-top: 15px;
  display: flex;
}
.Email_Body__2LY1f > div + div {
  margin-left: 15px;
}
@media all and (max-width: 768px) {
  .Email_Body__2LY1f {
    flex-direction: column;
  }
  .Email_Body__2LY1f > div + div {
    margin-left: 0;
    margin-top: 15px;
  }
}
.Email_Email__1-CDu .Email_EditPanel__24BiW {
  display: flex;
}
.Email_Email__1-CDu .Email_EditPanel__24BiW > input + button {
  margin-left: 5px;
}
.Email_Email__1-CDu .Email_EditPanel__24BiW > input {
  max-width: 200px;
  margin-left: 5px;
}
.Email_Email__1-CDu .Email_EditPanel__24BiW > span {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.Email_Email__1-CDu .Email_EditPanel__24BiW button {
  padding: 0;
}
.Email_Email__1-CDu .Email_EditPanel__24BiW button span {
  display: flex;
  justify-content: center;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.FullName_DangerColor__2bYuf {
  color: #ff8a4d;
}
.FullName_WarningColor__2uaqe {
  color: #ff8a4d;
}
.FullName_PrimaryColor__3LJbr {
  color: #1c58e8;
}
.FullName_SuccessColor__aSciG {
  color: #46d485;
}
.FullName_DangerButton__23Js- {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.FullName_DangerButton__23Js-:hover,
.FullName_DangerButton__23Js-:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.FullName_DangerButton__23Js-:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.FullName_WarningButton__34ka- {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.FullName_WarningButton__34ka-:hover,
.FullName_WarningButton__34ka-:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.FullName_WarningButton__34ka-:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.FullName_PrimaryButton__2owZO {
  background: #1c58e8;
  border-color: #1c58e8;
}
.FullName_PrimaryButton__2owZO:hover,
.FullName_PrimaryButton__2owZO:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.FullName_PrimaryButton__2owZO:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.FullName_SuccessButton__3y7PE {
  background: #46d485;
  border-color: #46d485;
}
.FullName_SuccessButton__3y7PE:hover,
.FullName_SuccessButton__3y7PE:focus {
  background: #46d485;
  border-color: #46d485;
}
.FullName_SuccessButton__3y7PE:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.FullName_Body__28irJ {
  margin-top: 15px;
  display: flex;
}
.FullName_Body__28irJ > div + div {
  margin-left: 15px;
}
@media all and (max-width: 768px) {
  .FullName_Body__28irJ {
    flex-direction: column;
  }
  .FullName_Body__28irJ > div + div {
    margin-left: 0;
    margin-top: 15px;
  }
}
.FullName_FullName__3wprl .FullName_EditPanel__3Dn7J {
  min-width: 350px;
  display: flex;
}
.FullName_FullName__3wprl .FullName_EditPanel__3Dn7J > input + button {
  margin-left: 5px;
}
.FullName_FullName__3wprl .FullName_EditPanel__3Dn7J > input {
  max-width: 200px;
  margin-left: 5px;
}
.FullName_FullName__3wprl .FullName_EditPanel__3Dn7J > span {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.FullName_FullName__3wprl .FullName_EditPanel__3Dn7J button {
  padding: 0;
}
.FullName_FullName__3wprl .FullName_EditPanel__3Dn7J button span {
  display: flex;
  justify-content: center;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.MobilePhone_DangerColor__2mJ_w {
  color: #ff8a4d;
}
.MobilePhone_WarningColor__1Wwo3 {
  color: #ff8a4d;
}
.MobilePhone_PrimaryColor__3JL44 {
  color: #1c58e8;
}
.MobilePhone_SuccessColor__3vvXO {
  color: #46d485;
}
.MobilePhone_DangerButton__33AoU {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.MobilePhone_DangerButton__33AoU:hover,
.MobilePhone_DangerButton__33AoU:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.MobilePhone_DangerButton__33AoU:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.MobilePhone_WarningButton__1XZzS {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.MobilePhone_WarningButton__1XZzS:hover,
.MobilePhone_WarningButton__1XZzS:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.MobilePhone_WarningButton__1XZzS:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.MobilePhone_PrimaryButton__2anqH {
  background: #1c58e8;
  border-color: #1c58e8;
}
.MobilePhone_PrimaryButton__2anqH:hover,
.MobilePhone_PrimaryButton__2anqH:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.MobilePhone_PrimaryButton__2anqH:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.MobilePhone_SuccessButton__3H6IL {
  background: #46d485;
  border-color: #46d485;
}
.MobilePhone_SuccessButton__3H6IL:hover,
.MobilePhone_SuccessButton__3H6IL:focus {
  background: #46d485;
  border-color: #46d485;
}
.MobilePhone_SuccessButton__3H6IL:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.MobilePhone_Body__MSNZe {
  margin-top: 15px;
  display: flex;
}
.MobilePhone_Body__MSNZe > div + div {
  margin-left: 15px;
}
@media all and (max-width: 768px) {
  .MobilePhone_Body__MSNZe {
    flex-direction: column;
  }
  .MobilePhone_Body__MSNZe > div + div {
    margin-left: 0;
    margin-top: 15px;
  }
}
.MobilePhone_MobilePhone__1AHy2 .MobilePhone_EditPanel__2vD-z {
  display: flex;
}
.MobilePhone_MobilePhone__1AHy2 .MobilePhone_EditPanel__2vD-z > input + button {
  margin-left: 5px;
}
.MobilePhone_MobilePhone__1AHy2 .MobilePhone_EditPanel__2vD-z > input {
  max-width: 200px;
  margin-left: 5px;
}
.MobilePhone_MobilePhone__1AHy2 .MobilePhone_EditPanel__2vD-z > span {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.MobilePhone_MobilePhone__1AHy2 .MobilePhone_EditPanel__2vD-z button {
  padding: 0;
}
.MobilePhone_MobilePhone__1AHy2 .MobilePhone_EditPanel__2vD-z button span {
  display: flex;
  justify-content: center;
}



.EditModal_EditModal__1FhKY input + input {
  margin-top: 10px;
}
.EditModal_EditModal__1FhKY .ant-select-selection-item-remove {
  display: flex;
  align-items: center;
}
.EditModal_EditModal__1FhKY .EditModal_TextBold__Rk51i {
  text-transform: capitalize;
}

.ResetPassword_ResetPassword__A5oW1 .ResetPassword_Button__3fbjq {
  padding: 0;
}

.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Roles_DangerColor__Y4N0b {
  color: #ff8a4d;
}
.Roles_WarningColor__3xics {
  color: #ff8a4d;
}
.Roles_PrimaryColor__1uTEg {
  color: #1c58e8;
}
.Roles_SuccessColor__pFfIr {
  color: #46d485;
}
.Roles_DangerButton__3ZSiY {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Roles_DangerButton__3ZSiY:hover,
.Roles_DangerButton__3ZSiY:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Roles_DangerButton__3ZSiY:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Roles_WarningButton__2NDqY {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Roles_WarningButton__2NDqY:hover,
.Roles_WarningButton__2NDqY:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Roles_WarningButton__2NDqY:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Roles_PrimaryButton__2pD_q {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Roles_PrimaryButton__2pD_q:hover,
.Roles_PrimaryButton__2pD_q:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Roles_PrimaryButton__2pD_q:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Roles_SuccessButton__zwpCD {
  background: #46d485;
  border-color: #46d485;
}
.Roles_SuccessButton__zwpCD:hover,
.Roles_SuccessButton__zwpCD:focus {
  background: #46d485;
  border-color: #46d485;
}
.Roles_SuccessButton__zwpCD:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Roles_Body__2nDW2 {
  margin-top: 15px;
  display: flex;
}
.Roles_Body__2nDW2 > div + div {
  margin-left: 15px;
}
@media all and (max-width: 768px) {
  .Roles_Body__2nDW2 {
    flex-direction: column;
  }
  .Roles_Body__2nDW2 > div + div {
    margin-left: 0;
    margin-top: 15px;
  }
}
@media all and (max-width: 768px) {
  .Roles_Tag__3o7uw {
    margin-top: 5px;
  }
}



.EditModal_EditModal__1Tomq input + input {
  margin-top: 10px;
}
.EditModal_EditModal__1Tomq .ant-select-selection-item-remove {
  display: flex;
  align-items: center;
}
.EditModal_EditModal__1Tomq .EditModal_TextBold__3T-rJ {
  text-transform: capitalize;
}

.UnlockIntrusion_UnlockIntrusion__3CJws .UnlockIntrusion_Button__uPlPu {
  padding: 0;
}


.ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
.ant-card, .ant-spin-nested-loading, .ant-page-header, .ant-btn, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper, .ant-input, .ant-modal-content {
  border-radius: 4px;
}
.Header_DangerColor__1eS2y {
  color: #ff8a4d;
}
.Header_WarningColor__3aLw9 {
  color: #ff8a4d;
}
.Header_PrimaryColor__i-tgD {
  color: #1c58e8;
}
.Header_SuccessColor__5xBxw {
  color: #46d485;
}
.Header_DangerButton__2sNdS {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_DangerButton__2sNdS:hover,
.Header_DangerButton__2sNdS:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_DangerButton__2sNdS:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Header_WarningButton__2-mk2 {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_WarningButton__2-mk2:hover,
.Header_WarningButton__2-mk2:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.Header_WarningButton__2-mk2:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.Header_PrimaryButton__2k7B2 {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Header_PrimaryButton__2k7B2:hover,
.Header_PrimaryButton__2k7B2:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.Header_PrimaryButton__2k7B2:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.Header_SuccessButton__3GkCC {
  background: #46d485;
  border-color: #46d485;
}
.Header_SuccessButton__3GkCC:hover,
.Header_SuccessButton__3GkCC:focus {
  background: #46d485;
  border-color: #46d485;
}
.Header_SuccessButton__3GkCC:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
.Header_Header__3X6df {
  border: 1px solid #ebedf0;
  background: white;
}

.List_List__271y1 {
  margin-top: 15px;
}
.List_List__271y1 .List_Title__3Hs65 {
  display: flex;
  align-items: flex-end;
}
.List_List__271y1 .List_Title__3Hs65 h5 {
  margin-bottom: 0;
  margin-right: 10px;
}
.List_List__271y1 .List_Block__2AIan + .List_Block__2AIan {
  margin-top: 15px;
}


@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4n.ttf) format('truetype');
}
:global .ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
:global .ant-btn-dangerous:hover,
:global .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
:global .ant-card,
:global .ant-spin-nested-loading,
:global .ant-page-header,
:global .ant-btn,
:global .ant-select-selector,
:global .ant-picker,
:global .ant-input-affix-wrapper,
:global .ant-input,
:global .ant-modal-content {
  border-radius: 4px;
}
:export {
  sm: 768px;
  md: 1024px;
  lg: 1200px;
}
.DangerColor {
  color: #ff8a4d;
}
.WarningColor {
  color: #ff8a4d;
}
.PrimaryColor {
  color: #1c58e8;
}
.SuccessColor {
  color: #46d485;
}
.DangerButton {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.DangerButton:hover,
.DangerButton:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.DangerButton:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.WarningButton {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.WarningButton:hover,
.WarningButton:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.WarningButton:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.PrimaryButton {
  background: #1c58e8;
  border-color: #1c58e8;
}
.PrimaryButton:hover,
.PrimaryButton:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.PrimaryButton:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.SuccessButton {
  background: #46d485;
  border-color: #46d485;
}
.SuccessButton:hover,
.SuccessButton:focus {
  background: #46d485;
  border-color: #46d485;
}
.SuccessButton:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
body {
  background-color: #f1f4f6;
  font-family: 'Open Sans', sans-serif;
}
.ant-table .ant-table-content .ant-table-body table {
  table-layout: fixed;
  border-collapse: collapse;
}
@media all and (max-width: 1024px) {
  .ant-table {
    overflow-x: scroll;
  }
  .ant-table table {
    min-width: 846px;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}

