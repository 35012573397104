.Password {
  .Button {
    display: flex;
    align-items: center;

    .Icon {
      display: flex;
      align-items: center;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;