@import 'shared/assets/styles/main.module.less';

.ReturnCard {
  :global {
    .ant-card-body {
      padding: 10px;
    }
  }

  .Body {
    margin-top: 15px;
    padding: 9px;
  }

  .Header {
    display: flex;
    align-items: center;

    .Icon {
      font-size: 20px;
      color: @primary;
      cursor: pointer;
    }

    .Title {
      margin-left: 10px;
      margin-right: 5px;
      font-size: 20px;
      color: #c4c4c4;
    }
  }

  .SubTitle {
    font-size: 17px;
    margin: 0 9px;
    margin-top: 10px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;