@import 'shared/assets/styles/app-global-less-styles.less';
@import '../../Authentication.module.less';

.ResetPassword {
  width: 320px;

  input + input {
    margin-top: 10px;
  }

  .ControlPanel {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;

    .ResetPassword {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;