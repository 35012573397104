@import '../../shared/assets/styles/main.module.less';

:export {
  primary: @primary;
}

.Authentication {
  height: 100%;
  min-height: 100vh;
  display: flex;

  background: linear-gradient(90deg, @primary 50%, #fff0 0);

  .Body {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: 5vh;

    > svg {
      z-index: 10;
      height: 30vh;
      width: 50vw;
      padding-bottom: 10vh;
    }
  }

  .Routers {
    min-width: 320px;
    max-width: 600px;
    margin-bottom: 50px;

    > div {
      min-width: 320px;
    }
  }
}

.Show {
  display: unset;
}

.Hiden {
  display: none;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;