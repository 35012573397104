.Reporting {
  .RangePicker,
  .Select {
    width: 100%;
  }

  > div + div {
    margin-top: 10px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;