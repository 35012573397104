.FeedBack {
  .RadioGroup {
    display: flex;
    flex-direction: column;
  }

  .TextArea {
    margin-top: 20px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;