.DeliveryRequest {
  .Body {
    display: flex;
    height: fit-content;

    > * + * {
      margin-left: 15px;
    }
    > * {
      width: calc(50% - 15px / 2);
    }

    .Card {
      height: fit-content;
    }
  }
}

.Hide {
  display: none !important;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;