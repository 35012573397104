.AutoCompleteAddress {
  width: 100%;
  position: relative;

  .AutoComplete {
    width: 100%;
  }

  .Spiner {
    position: absolute;
    top: calc(50% - 7px);
    right: 5px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;