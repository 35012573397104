@import 'shared/assets/styles/main.module.less';
@import '../../Authentication.module.less';

@import (css) url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@700&display=swap');

.CheckTwoFa {
  @media all and (max-width: 500px) {
    width: auto;
  }

  .ControlPanel {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .ResetPassword {
      padding-right: 0;
      padding-left: 0;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Input {
    color: @description;
    font-size: 50px;
    border: 0;
    text-align: center;
    font-family: 'Inconsolata', monospace;
  }

  span + input {
    margin-top: 10px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;