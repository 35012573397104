@import 'shared/assets/styles/main.module.less';

:export {
  primaryColor: @primary-color;
}

.Map {
  [class*='ymaps-2'][class*='-ground-pane'] {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;