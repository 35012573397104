.AutoCompleteFio {
  width: 100%;

  :global {
    .ant-input-search-button,
    .anticon + .anticon-search {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;