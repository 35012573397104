@import 'shared/assets/styles/main.module.less';
@import '../../Authentication.module.less';

.ChoiceTwoFa {
  .SelectChoiceTwoFa {
    width: 100%;
  }

  .ControlPanel {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .ResetPassword {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .Description {
    color: #000;

    span + div {
      margin-top: 10px;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;