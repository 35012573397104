@primary: rgb(28, 88, 232);
@secondary: #b270dc;
@success: #46d485;
@danger: #e26a6a;
@warning: #ff8a4d;
@info: #60cbe4;
@description: #9e9c9c;
//   "light":      $light,
@dark: #301f92;
@yellow: #fbdb13;

@primary-color: @primary;
@warning-color: @warning;
@success-color: @success;
@error-color: @secondary;

@sm: 768px;
@md: 1024px;
@lg: 1200px;

@widthApp: calc(100% - 40px);
@widthAppLg: 100%;
@widthAppMd: @widthAppLg;
@widthAppSm: @widthAppMd;

:global {
  .ant-btn-dangerous {
    color: @danger;
    background: #fff;
    border-color: @danger;

    &:hover,
    &:focus {
      color: @error-color;
      background: #fff;
      border-color: @error-color;
    }
  }

  .ant-card,
  .ant-spin-nested-loading,
  .ant-page-header,
  .ant-btn,
  .ant-select-selector,
  .ant-picker,
  .ant-input-affix-wrapper,
  .ant-input,
  .ant-modal-content {
    border-radius: 4px;
  }
}
