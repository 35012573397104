@import '../../shared/assets/styles/main.module.less';

.Dashboard {
  display: flex;

  .Menu {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(1, calc(70vw / 12));
    grid-template-rows: repeat(7, calc((70vw - 5px * 11) / 12));

    @media all and (max-width: @lg) {
      grid-template-columns: repeat(1, calc(100vw / 8));
      grid-template-rows: repeat(7, calc((100vw - 5px * 7 - 10px * 2) / 8));
    }

    @media all and (max-width: @sm) {
      display: none;
    }

    margin-right: 5px;
  }

  .Tiles {
    display: grid;
    grid-gap: 5px;
    width: 100%;

    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(7, calc((70vw - 5px * 11) / 12));

    grid-template-areas:
      'Products     Products     Products     Products     Products     CreatedData   CreatedData  CreatedData  StatusBar StatusBar StatusBar'
      'Products     Products     Products     Products     Products     CreatedData   CreatedData  CreatedData  StatusBar StatusBar StatusBar'
      'Products     Products     Products     Products     Products     Users         Users        Users        StatusBar StatusBar StatusBar'
      'Products     Products     Products     Products     Products     Users         Users        Users        StatusBar StatusBar StatusBar'
      'DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate  DeliveryDate DeliveryDate Payments  Payments  Payments'
      'DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate  DeliveryDate DeliveryDate Payments  Payments  Payments'
      'DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate  DeliveryDate DeliveryDate -         -         -'
      'DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate  DeliveryDate DeliveryDate -         -         -';

    @media all and (max-width: @lg) {
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(7, calc((100vw - 5px * 7 - 10px * 2) / 8));

      grid-template-areas:
        'Products      Products    Products     Products     Products     StatusBar    StatusBar    StatusBar'
        'Products      Products    Products     Products     Products     StatusBar    StatusBar    StatusBar'
        'Products      Products    Products     Products     Products     StatusBar    StatusBar    StatusBar'
        'Products      Products    Products     Products     Products     StatusBar    StatusBar    StatusBar'
        'CreatedData   CreatedData DeliveryDate DeliveryDate DeliveryDate Payments     Payments     Payments'
        'CreatedData   CreatedData DeliveryDate DeliveryDate DeliveryDate Payments     Payments     Payments'
        'Users         Users       DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate'
        'Users         Users       DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate DeliveryDate';
    }

    @media all and (max-width: @sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(7, calc((100vw - 5px * 3) / 2));

      grid-template-areas:
        'Products     Products'
        'Products     Products'
        'Users        Users'
        'StatusBar    StatusBar'
        'StatusBar    StatusBar'
        'CreatedData  CreatedData'
        'Payments     Payments'
        'DeliveryDate DeliveryDate';
    }

    .DeliveryDate {
      grid-area: DeliveryDate;
    }

    .StatusBar {
      grid-area: StatusBar;
    }

    .Payments {
      grid-area: Payments;
    }

    .CreatedData {
      grid-area: CreatedData;
    }

    .Products {
      grid-area: Products;
    }

    .Users {
      grid-area: Users;
    }

    .Buttons {
      grid-area: Buttons;
    }
  }

  .Disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;