@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
@import './shared/assets/styles/main.module.less';

body {
  background-color: #f1f4f6;
  font-family: 'Open Sans', sans-serif;
}

.ant-table {
  .ant-table-content {
    .ant-table-body {
      table {
        table-layout: fixed;
        border-collapse: collapse;
      }
    }
  }

  @media all and (max-width: @md) {
    overflow-x: scroll;

    table {
      min-width: 846px;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;