@import '../../../../assets/styles/main.module.less';

.RejectModal {
  .RadioGroup {
    display: flex;
    flex-direction: column;
  }

  .Comment {
    margin-top: 1rem;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;