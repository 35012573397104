@import '../../../../shared/assets/styles/main.module.less';

.CreatedData {
  height: 100%;

  .Body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc((70vw - 5px * 11) / 12 * 2 - 58px - 48px);

    @media all and (max-width: @lg) {
      height: calc((100vw - 5px * 7) / 8 * 2 - 58px - 48px);
    }

    @media all and (max-width: @sm) {
      height: calc((100vw - 5px * 3) / 2 - 58px - 48px);
    }

    .Item {
      display: flex;
      align-items: center;

      > :first-child {
        flex: 1;
        line-height: 1;

        font-size: calc(((70vw - 5px * 11) / 12 * 2 - 58px - 48px) / 5);

        @media all and (max-width: @lg) {
          font-size: calc(((100vw - 5px * 7) / 8 * 2 - 58px - 48px) / 5);
        }

        @media all and (max-width: @sm) {
          font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 5);
        }
      }

      > :last-child {
        padding-bottom: 0;
        line-height: 1;

        font-size: calc(((70vw - 5px * 11) / 12 * 2 - 58px - 48px) / 3);

        @media all and (max-width: @lg) {
          font-size: calc(((100vw - 5px * 7) / 8 * 2 - 58px - 48px) / 3);
        }

        @media all and (max-width: @sm) {
          font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 3);
        }
      }
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;