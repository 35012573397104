@import '../../../../shared/assets/styles/main.module.less';

.Reporting {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  :global {
    .ant-card-body {
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .Button {
    height: 100%;
    width: 100%;
    padding: 0;

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: calc((70vw - 5px * 11) / 12 - 10px - 16px);

      @media all and (max-width: @lg) {
        font-size: calc((100vw - 5px * 7) / 8 - 20px - 8px);
      }

      @media all and (max-width: @sm) {
        font-size: calc((100vw - 5px * 3) / 2 - 20px - 4px);
      }
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;