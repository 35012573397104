.TaskCommentsWidget {
  border-radius: 15px !important;

  :global {
    .ant-card-body {
      padding: 0 !important;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;