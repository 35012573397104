@import '../../../../shared/assets/styles/main.module.less';

.Products {
  height: 100%;

  .Block {
    height: calc((70vw - 5px * 11) / 12 * 4 - 58px - 48px);

    @media all and (max-width: @lg) {
      height: calc((100vw - 5px * 7) / 8 * 4 - 58px - 48px);
    }

    @media all and (max-width: @sm) {
      height: calc((100vw - 5px * 3) - 58px - 48px);
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;