.SelectEmail {
  &,
  .Select {
    width: 100%;
  }

  .Hint {
    font-size: 12px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;