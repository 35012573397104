@import 'shared/assets/styles/main.module.less';

.Request {
  > div + div {
    margin-top: 10px;
  }

  .Status {
    display: flex;

    > span {
      margin-right: 0;
      text-align: center;
    }

    > span + span {
      margin-left: 8px;
    }

    @media all and (max-width: @sm) {
      flex-direction: column-reverse;
    }
  }
}

.Title {
  display: flex;
  flex-direction: row;

  .SubTilte {
    height: 14px;

    @media all and (max-width: @sm) {
      height: unset;
    }

    > span {
      margin-left: 10px;
      font-size: 12px;

      @media all and (max-width: @sm) {
        margin-left: 0;
      }
    }
  }

  @media all and (max-width: @sm) {
    flex-direction: column;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;