.QrCode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.Os {
  margin-top: 17px;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;