@import '../../../../shared/assets/styles/main.module.less';

.Table {
  margin-top: 15px;
  overflow: hidden;
  width: auto;

  :global {
    .ant-table-row {
      cursor: pointer;
    }

    .ant-table-content {
      overflow: auto;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;