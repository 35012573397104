@import 'shared/assets/styles/main.module.less';

.WrapperField {
  display: flex;
  align-items: center;
  min-height: 32px;

  @media all and (max-width: @sm) {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .Title {
    line-height: 1;
    margin-right: 3px;
    color: rgba(0, 0, 0, 0.85);
    white-space: nowrap;
    margin-right: 10px;
  }

  .Data {
    display: flex;
    width: 100%;

    .Text {
      display: flex;
      align-items: center;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.45);

      @media all and (max-width: @sm) {
        flex-grow: 1;
        flex-wrap: wrap;
      }
    }

    .Button {
      display: flex;
      align-items: center;
      padding: 0;
      margin-left: 5px;

      .Icon {
        &:hover {
          svg {
            path {
              fill: rgb(28, 88, 232);
            }
          }
        }

        &:clock {
          svg {
            path {
              fill: #5e70cc;
            }
          }
        }

        svg {
          path {
            fill: #000;
          }
        }
      }
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;