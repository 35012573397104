@import '../../../../../../shared/assets/styles/main.module.less';

.ItemWrapper {
  height: calc(((70vw - 5px * 11) / 12 * 4 - 58px - 48px) / 7);

  @media all and (max-width: @lg) {
    height: calc(((100vw - 5px * 7) / 8 * 4 - 58px - 48px) / 7);

    :global {
      .ant-tag {
        padding: 8px 0;
      }
    }
  }

  @media all and (max-width: @sm) {
    height: calc(((100vw - 5px * 3) - 58px - 48px) / 7);

    :global {
      .ant-tag {
        padding: 5px 0;
      }
    }
  }

  display: flex;
  align-items: center;

  > :first-child {
    flex: 1;
  }

  > :last-child {
    padding-bottom: 0;
  }

  .Tag {
    text-align: center;
    line-height: 1;
    cursor: pointer;

    width: calc(((70vw - 5px * 11) / 12 * 4 - 58px - 48px) / 10 * 6.5);
    font-size: calc(((70vw - 5px * 11) / 12 * 4 - 58px - 48px) / 14);

    @media all and (max-width: @lg) {
      width: calc(((100vw - 5px * 7) / 8 * 4 - 58px - 48px) / 10 * 5.5);
      font-size: calc(((100vw - 5px * 7) / 8 * 4 - 58px - 48px) / 14);
    }

    @media all and (max-width: @sm) {
      width: calc(((100vw - 5px * 3) - 58px - 48px) / 10 * 8);
      font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 4);
    }
  }

  .Text {
    line-height: 1;
    cursor: pointer;

    font-size: calc(((70vw - 5px * 11) / 12 * 4 - 58px - 48px) / 9);

    @media all and (max-width: @lg) {
      font-size: calc(((100vw - 5px * 7) / 8 * 4 - 58px - 48px) / 9);
    }

    @media all and (max-width: @sm) {
      font-size: calc(((100vw - 5px * 3) / 2 - 58px - 48px) / 2);
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;