.SelectTimeInterval {
}

.SwitchExactInterval {
  margin-bottom: 10px;
}

.Select {
  width: 100%;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;