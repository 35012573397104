.Table {
  margin-top: 15px;

  .Switch {
    pointer-events: none;
    cursor: not-allowed;
  }

  :global {
    .ant-table-row {
      cursor: pointer;
    }
  }
}

.Filter {
  padding: 8px;
  max-width: 300px;

  > span + span {
    margin-top: 5px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;