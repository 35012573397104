body {
  font-size: 0.88rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

textarea:focus,
input:focus {
  outline: none;
}

.icon-button {
  color: white;

  &:focus {
    color: white;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;