.Comments {
  padding: 10px;
  max-height: 400px;
  overflow: auto;

  .Empty {
    display: flex;
    justify-content: center;

    > span {
      background-color: #a7a1a136;
      padding: 2px;
      font-size: 12px;
      border-radius: 8px;
      margin: 20px;
    }
  }

  > * + * {
    margin-top: 10px;
  }
}

.Message {
  .Wrapper {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .Reason {
      width: max-content;
      padding-bottom: 5px;

      > span {
        border-radius: 5px;
        padding: 0 2px;
        font-size: 12px;
      }
    }

    .Content {
      border: 0px;
      border-radius: 20px;
      width: max-content;
      max-width: 80%;
      min-width: 150px;
      padding: 1em 1.5em;
      padding-bottom: 3px;

      box-shadow: 0px 0px 24px -7px rgba(34, 60, 80, 0.21);

      .Text {
        color: #000;
        white-space: pre;
        white-space: pre-line;
      }

      .Actions {
        padding-top: 7px;
        display: flex;
        justify-content: space-between;

        font-size: 12px;

        > * + * {
          margin-left: 20px;

          > * + * {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.FrMessage {
  align-items: flex-end;

  .Content {
    margin-left: 50px;
    background-color: #eeffde;
  }

  .Reason {
    margin-right: 10px;
  }
}

.PartnerMessage {
  align-items: flex-start;

  .Content {
    margin-right: 50px;
    background-color: #b9dcee;
  }

  .Reason {
    margin-left: 10px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;