.Footer {
  padding: 20px 0;
  text-align: center;
  font-size: 14px;

  color: rgba(0, 0, 0, 0.45);

  .Bytton {
    padding: 0;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;