@import 'shared/assets/styles/app-global-less-styles.less';

.PhoneCallHistory {
  .Button {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }

  .Failed {
    color: @danger;
  }

  .Terminated,
  .InProgress,
  .In {
    color: @success;
  }

  .In,
  .Out {
    font-size: 21px;
  }

  .Transfer,
  .Pending {
    color: @warning;
  }

  .Paused,
  .PendingAnswer,
  .Out {
    color: @secondary;
  }

  .Call {
    display: flex;
    align-items: center;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;