@import 'shared/assets/styles/app-global-less-styles.less';

.DeliveryRequestsList {
  .PageHeader {
    border: 1px solid rgb(235, 237, 240);
    background: white;
  }

  :global {
    .ant-page-header-heading-extra {
      @media all and (max-width: @sm) {
        width: 100%;
      }
    }
  }

  .Extra {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 10px;
    }

    @media all and (max-width: @sm) {
      width: 100%;
      flex-direction: column;

      button {
        width: 100%;
      }
      > div + div {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;