//Функция затемнения тона
.darker(@color, @tone) {
  @adjust: -16 * @tone;
  @return: adjust-color(@color, @red: @adjust, @green: @adjust, $blue: @adjust);
}
// Функция осветления тона
.lighter(@color, @tone) {
  @adjust: 16 * @tone;
  @return: adjust-color(@color, @red: @adjust, @green: @adjust, $blue: @adjust);
}

@import './app-global-less-styles.less';

:export {
  sm: @sm;
  md: @md;
  lg: @lg;
}

.DangerColor {
  color: @error-color;
}

.WarningColor {
  color: @warning-color;
}

.PrimaryColor {
  color: @primary-color;
}

.SuccessColor {
  color: @success-color;
}

.DangerButton {
  background: @error-color;
  border-color: @error-color;

  &:hover,
  &:focus {
    background: @error-color;
    border-color: @error-color;
  }

  &:active {
    background: darker(@error-color, 1%);
    border-color: darker(@error-color, 1%);
  }
}

.WarningButton {
  background: @warning-color;
  border-color: @warning-color;

  &:hover,
  &:focus {
    background: @warning-color;
    border-color: @warning-color;
  }

  &:active {
    background: darker(@warning-color, 1%);
    border-color: darker(@warning-color, 1%);
  }
}

.PrimaryButton {
  background: @primary-color;
  border-color: @primary-color;

  &:hover,
  &:focus {
    background: @primary-color;
    border-color: @primary-color;
  }

  &:active {
    background: darker(@primary-color, 1%);
    border-color: darker(@primary-color, 1%);
  }
}

.SuccessButton {
  background: @success-color;
  border-color: @success-color;

  &:hover,
  &:focus {
    background: @success-color;
    border-color: @success-color;
  }

  &:active {
    background: darker(@success-color, 1%);
    border-color: darker(@success-color, 1%);
  }
}
