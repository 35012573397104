@import '../../../../shared/assets/styles/main.module.less';

.PersonalInformation {
  display: flex;

  .BlockOne {
    display: grid;
    grid-gap: 15px;
    min-width: 240px;

    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  .BlockTwo {
    width: 100%;

    display: flex;
    justify-content: flex-end;
  }

  @media all and(max-width: @sm) {
    flex-direction: column;

    .BlockTwo {
      margin-top: 10px;
      justify-content: flex-start;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;