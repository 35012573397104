.CreateUser {
  .Button {
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.Modal {
  > input + input,
  input + div,
  div + input {
    margin-top: 10px;
  }

  :global {
    .ant-select-selection-item-remove {
      display: flex;
      align-items: center;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;