@import 'shared/assets/styles/main.module.less';

.ErrorLines {
  > div {
    display: flex;
    flex-direction: column;
  }

  .Lines {
    width: 100%;
  }
}

.Console {
  display: flex;
  background-color: #000;
  padding: 10px;
  flex-direction: row;

  > div {
    &:first-child {
      max-height: 200px;
      overflow-y: scroll;
    }

    > span {
      line-height: 18px;
      margin-top: 8px;
    }

    > span,
    button {
      color: #fff;
    }

    > button {
      padding: 0;
      display: flex;
    }
  }
}

.Error {
  > div {
    > span {
      color: @danger;
    }
  }
}

.Success {
  > div {
    > span {
      color: @success;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;