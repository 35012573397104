@import 'shared/assets/styles/app-global-less-styles.less';

.ShowDocuments {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .Item {
    padding: 0.5rem;
    position: relative;
    .Title {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(177, 177, 177, 0.4);

      > button {
        padding: 0;
      }

      > span {
        button {
          padding: 0;
        }
      }

      > button + button {
        margin-left: 10px;
      }

      .Primary,
      .Danger {
        > span {
          color: #ffffff;
        }
      }

      .Primary {
        > span {
          &:hover {
            color: @primary-color;
          }
        }
      }

      .Danger {
        > span {
          &:hover {
            color: @error-color;
          }
        }
      }

      .Icon {
        font-size: 24px;
      }
    }

    .Image {
      transition: all 0.3s ease 0s;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      overflow: hidden;
    }
    .Image img {
      transition: all 0.3s ease 0s;
      filter: blur(0px);
      transform: scale(1.1);
      object-fit: cover;
      padding: 7px;
    }
    &:hover {
      .Title {
        opacity: 1;
      }
      .Image {
        filter: blur(2px);
      }
    }
    /* for touch screen devices */
    @media (hover: none) {
      .Title {
        opacity: 1;
      }
      .Image {
        filter: blur(2px);
      }
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;