@import '../../../../shared/assets/styles/main.module.less';

.Users {
  height: 100%;

  .Active {
    cursor: pointer;
  }

  .Button {
    padding: 0;
    display: flex;
    align-items: center;
  }

  .CountUsers {
    width: 100%;
    text-align: center;
    line-height: 1;

    font-size: calc((70vw - 5px * 11) / 12 * 2 - 58px - 48px);

    @media all and (max-width: @lg) {
      font-size: calc((100vw - 5px * 7) / 8 * 2 - 58px - 48px);
    }

    @media all and (max-width: @sm) {
      font-size: calc((100vw - 5px * 3) / 2 - 58px - 48px);
    }
  }
}

.Disabled {
  pointer-events: none;
  opacity: 0.4;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;