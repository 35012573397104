@import '../../../../assets/styles/main.module.less';

:export {
  primaryColor: @primary-color;
}

@mixin one-third {
  width: calc(70% / 3);

  @media (max-width: @md) {
    width: calc(100% / 3);
  }
}

.Header {
  height: 60px;
  background-color: white;

  .Content {
    max-width: @widthApp;

    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .Menu {
      display: unset;

      @include one-third;
    }

    .Logo {
      cursor: pointer;
      display: flex;
      justify-content: center;

      .LogoPartner {
        max-width: 97px;

        @media all and (max-width: 360px) {
          max-width: 70px;
        }
      }

      @include one-third;
    }

    .Button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px;

      @include one-third;

      .Title {
        color: #a9a4a4;
        font-size: 16px;
        mix-blend-mode: normal;
        opacity: 0.85;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
        margin-bottom: 0;

        @media (max-width: @md) {
          display: none;
        }
      }

      > span {
        display: flex;
        align-items: center;
      }
    }

    @media all and (max-width: @lg) {
      max-width: @widthAppLg;
    }
  }
}

.Email {
  padding: 8px 16px;
  color: #a9a4a4;
  font-size: 14px;
  line-height: 1.5715;
  cursor: unset;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #fff !important;
  }
}

.Item {
  display: flex;
  align-items: center;
  margin: 2px;
  padding: 5px 10px;
  cursor: pointer;

  span + span {
    margin-left: 0;
  }

  span {
    svg {
      font-size: 18px;
      color: @primary-color;
    }
  }

  &:hover {
    background-color: rgba(lighter(@primary-color, 5%), 0.5);
    border-radius: 5px;
  }
}

.Devider {
  padding: 0;
  cursor: unset;

  .Divider {
    margin: 5px 0;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;