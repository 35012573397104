.MoreMenu {
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    display: flex;
    align-items: center;
  }
}

.Item {
  display: flex;
  align-items: center;

  > span {
    font-size: 16px !important;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;