.Sms {
}

.CountdownTimer {
  padding-left: 0;
}

.HintPhone {
  width: 100%;
  display: flex;

  justify-content: center;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;