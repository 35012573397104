.EditModal {
  input + input {
    margin-top: 10px;
  }

  :global {
    .ant-select-selection-item-remove {
      display: flex;
      align-items: center;
    }
  }

  .TextBold {
    text-transform: capitalize;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;