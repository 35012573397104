@import '../../shared/assets/styles/main.module.less';

.Profile {
  border: 1px solid rgb(235, 237, 240);
  background: white;

  :global {
    .ant-page-header-content {
      padding-top: 0;
    }
  }
}

.Cards {
  margin-top: 15px;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;

  @media all and (max-width: @sm) {
    grid-template-columns: 1fr;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;