.Header {
  :global {
    .ant-page-header {
      padding-top: 0;
      background-color: #fff;
      margin-bottom: 10px;

      .ant-page-header-heading {
        padding-top: 10px;

        .ant-page-header-heading-title {
          padding: 0px;
          display: inline-block;
        }
      }
    }

    .ant-page-header-heading-extra {
      display: flex;
      align-items: center;
    }
  }
}

.Status {
  display: flex;
  align-items: center;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;