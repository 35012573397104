.List {
  margin-top: 15px;

  .Title {
    display: flex;
    align-items: flex-end;

    h5 {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  .Block + .Block {
    margin-top: 15px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;