.Address {
  display: flex;
  flex-direction: column;

  .Map {
    flex: 2;
  }

  :global {
    .ant-card {
      flex: 1;
    }
  }

  .Status {
    > span {
      margin-right: 0;
    }

    > span + span {
      margin-left: 8px;
    }
  }

  .FailureReasons {
    margin-top: 15px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;