@import 'shared/assets/styles/main.module.less';

.Form {
  > * + * {
    margin-top: 10px;
  }

  .Double {
    display: flex;

    > * {
      flex: 1;
    }

    > * + * {
      margin-left: 10px;
    }

    @media all and (max-width: @sm) {
      flex-direction: column;

      > * + * {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;