@import '../../assets/styles/main.module.less';

.Container {
  margin-top: 20px !important;
  // display: flex;

  // max-width: @widthApp;
  margin: 0 auto;

  max-width: @widthApp;

  @media all and (max-width: @lg) {
    margin: 10px 10px 0 10px !important;
    max-width: @widthAppLg;
  }

  @media all and (max-width: @sm) {
    margin-top: 10px !important;
    margin: 10px 5px 0 5px !important;
    max-width: @widthAppSm;
  }

  display: block;

  .SideBar {
    display: none;
  }
  // }

  .Content {
    flex: 1;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;