@import '../../Body.module.less';

.Roles {
}

.Tag {
  @media all and (max-width: @sm) {
    margin-top: 5px;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;