.CreateComment {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  button {
    width: min-content;
  }

  .TextArea {
    width: 100%;
    border-radius: 0 0 15px 15px;
    display: flex;
    padding: 0;

    > textarea {
      padding: 10px;
      resize: none;
      border: 0;
      background: transparent;
      outline: none;
      flex: 1;
    }

    button {
      cursor: pointer;
      background: transparent;
      border: 0 solid transparent;
    }

    .Actions {
      padding: 10px;
      padding-left: 0;
      display: flex;
      flex-direction: column;

      .Buttons {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  :global {
    .ant-input:focus {
      box-shadow: unset;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;