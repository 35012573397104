@import '../../Body.module.less';

.MobilePhone {
  .EditPanel {
    display: flex;

    > input + button {
      margin-left: 5px;
    }

    > input {
      max-width: 200px;
      margin-left: 5px;
    }

    > span {
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    button {
      padding: 0;

      span {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;