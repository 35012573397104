@import '../../../../assets/styles/main.module.less';

.Menu {
  min-width: 200px;

  margin-right: 15px;

  .Disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .Item {
    display: flex;
    align-items: center;
    margin: 2px;
    padding: 5px 10px;
    cursor: pointer;

    span + span {
      margin-left: 10px;
    }

    span {
      svg {
        font-size: 18px;
        color: @primary-color;
      }
    }

    &:hover {
      background-color: rgba(lighter(@primary-color, 5%), 0.5);
      border-radius: 5px;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;