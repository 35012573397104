.DataRequest {
  .Badge {
    margin-bottom: 0;
  }
}

.Button {
  padding: 0;
  margin-left: 15px;

  .BodyButton {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
}

.CompanyName {
  margin-right: 10px;
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;