.HistoryDelivery {
  display: flex;
  justify-content: center;

  margin: 20px 0;

  .OpenButton {
    display: flex;
    align-items: center;
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;