:global .ant-btn-dangerous {
  color: #e26a6a;
  background: #fff;
  border-color: #e26a6a;
}
:global .ant-btn-dangerous:hover,
:global .ant-btn-dangerous:focus {
  color: #ff8a4d;
  background: #fff;
  border-color: #ff8a4d;
}
:global .ant-card,
:global .ant-spin-nested-loading,
:global .ant-page-header,
:global .ant-btn,
:global .ant-select-selector,
:global .ant-picker,
:global .ant-input-affix-wrapper,
:global .ant-input,
:global .ant-modal-content {
  border-radius: 4px;
}
:export {
  sm: 768px;
  md: 1024px;
  lg: 1200px;
}
.DangerColor {
  color: #ff8a4d;
}
.WarningColor {
  color: #ff8a4d;
}
.PrimaryColor {
  color: #1c58e8;
}
.SuccessColor {
  color: #46d485;
}
.DangerButton {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.DangerButton:hover,
.DangerButton:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.DangerButton:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.WarningButton {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.WarningButton:hover,
.WarningButton:focus {
  background: #ff8a4d;
  border-color: #ff8a4d;
}
.WarningButton:active {
  background: darker(#ff8a4d, 1%);
  border-color: darker(#ff8a4d, 1%);
}
.PrimaryButton {
  background: #1c58e8;
  border-color: #1c58e8;
}
.PrimaryButton:hover,
.PrimaryButton:focus {
  background: #1c58e8;
  border-color: #1c58e8;
}
.PrimaryButton:active {
  background: darker(#1c58e8, 1%);
  border-color: darker(#1c58e8, 1%);
}
.SuccessButton {
  background: #46d485;
  border-color: #46d485;
}
.SuccessButton:hover,
.SuccessButton:focus {
  background: #46d485;
  border-color: #46d485;
}
.SuccessButton:active {
  background: darker(#46d485, 1%);
  border-color: darker(#46d485, 1%);
}
