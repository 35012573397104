.DownloadAllPhotosButton {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 22px;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;