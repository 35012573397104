@import '../../../../shared/assets/styles/main.module.less';
@import '../../Authentication.module.less';

.Auth {
  width: 400px;
  input + input {
    margin-top: 10px;
  }

  .ControlPanel {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .ResetPassword {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@primary-color: rgb(28, 88, 232);@error-color: #ff8a4d;