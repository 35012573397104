@import 'shared/assets/styles/main.module.less';

.Body {
  margin-top: 15px;

  display: flex;

  > div + div {
    margin-left: 15px;
  }

  @media all and (max-width: @sm) {
    flex-direction: column;

    > div + div {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}
